import dayjs from 'dayjs'
import { type Dispatch, type SetStateAction, useMemo } from 'react'

import { FvLinkButton } from '@fv/client-components'

import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import { AuditPriceDisplay } from './components/AuditPriceDisplay'
import { AuditRateBreakdown } from './components/AuditRateBreakdown'
import { AuditCardArrowCtn } from './AuditCardArrowCtn'
import AuditNotes from './AuditNotes'
import { useResetAudit } from './queries'
import { type Audit } from './types'

type AcceptedAuditFormProps = {
  audit: Audit
  isActive: boolean
  setActive: Dispatch<SetStateAction<string | null>>
}

const dateFormat = 'ddd MMM D, YYYY'

const AcceptedAuditForm = ({
  audit,
  isActive,
  setActive,
}: AcceptedAuditFormProps) => {
  const { invoiceNumber, invoiceDate, loadId, notes, payments } = audit
  const { resetAudit, isResettingAudit } = useResetAudit(audit, 'accepted')

  const pendingPaymentAmount = useMemo(() => {
    if (!payments?.length) return 0

    return payments.reduce((total, p) => {
      if (!p.remitted) total += p.amount
      return total
    }, 0)
  }, [payments])

  return (
    <>
      <div className="flex items-center gap-2">
        <AuditPriceDisplay amount={pendingPaymentAmount} />
        <InputGroup
          className="mb-0"
          inputType="text"
          inputProps={{
            name: 'Invoice number',
            placeholder: 'Invoice number',
            onChange: undefined,
            value: invoiceNumber ?? '',
            label: '',
            readOnly: true,
          }}
          startContent={<InputAdornment position="start" icon="hashtag" />}
        />
        <InputGroup
          className="mb-0 w-52"
          inputType="text"
          inputProps={{
            name: 'Invoice date',
            placeholder: 'Invoice dates',
            onChange: undefined,
            value: invoiceDate ? dayjs(invoiceDate).format(dateFormat) : '',
            readOnly: true,
          }}
          label=""
          startContent={
            <InputAdornment
              position="start"
              icon="calendar-day"
              iconClass="color-dark"
            />
          }
        />
        <div className="divided-content divided-content--end divided-content--start ml-2">
          <FvLinkButton
            className=""
            onClick={() => {
              !isResettingAudit && resetAudit(loadId)
            }}
            icon={isResettingAudit ? 'spinner' : 'file-invoice-dollar'}
          >
            <span className="b1400:hidden">Move back to outstanding</span>
            <span className="hidden b1400:inline-block">
              Move to outstanding
            </span>
          </FvLinkButton>
          <FvLinkButton
            onClick={() => {
              setActive(prev => (prev === loadId ? null : loadId))
            }}
            icon={`caret-circle-${isActive ? 'up' : 'down'}`}
          >
            <span className="b1350:hidden">Details</span>
          </FvLinkButton>
        </div>
      </div>
      {isActive && (
        <AuditCardArrowCtn>
          <AuditRateBreakdown audit={audit} />
        </AuditCardArrowCtn>
      )}

      {isActive && <AuditNotes className="mt-4" notes={notes} />}
    </>
  )
}

export default AcceptedAuditForm
