import dayjs from 'dayjs'

import {
  type CarrierMode,
  carrierModeTypes,
  type CarrierProvisioningDetails,
  type CredentialDetail,
  type DetailedAccountProvisioningDto,
} from '@fv/client-types'

import { type AccountCarrierFormModel } from './types'

export const getCarrierModeDisplay = (
  override: string | undefined,
  mode: CarrierMode,
) => {
  if (override) return override

  if (mode === 'tl') return 'Truckload'
  if (mode === 'parcel') return 'Parcel'
  return 'LTL'
}

export const toFormModel = (
  val: DetailedAccountProvisioningDto | undefined,
): AccountCarrierFormModel => ({
  details: val?.details ?? [],
  salesRepEmails: val?.salesReps?.map(e => e.email).join() ?? '',
  salesRepFirstName: val?.salesRepFirstName ?? '',
  salesRepLastName: val?.salesRepLastName ?? '',
  salesRepPhone: val?.salesRepPhone ?? '',
  modes: val?.modes ?? [],
})

export const fillModeDefaults = (
  model: AccountCarrierFormModel,
  carrier: CarrierProvisioningDetails,
): AccountCarrierFormModel => ({
  ...model,
  modes: carrierModeTypes.map(modeType => ({
    modeType,
    enabled:
      model?.modes?.find(m => m.modeType === modeType)?.enabled ??
      carrier[modeType].enabled,
  })),
})

export const isBetaEnabled = (
  carrier: CarrierProvisioningDetails,
  current?: DetailedAccountProvisioningDto,
): boolean => {
  return !!current?.isBeta || (!current && carrier.betaProvisioning)
}

export function needsShipperAttention({
  code,
  details,
}: {
  code?: string
  details?: CredentialDetail[]
  isBeta?: boolean
}) {
  if (code === 'upgf' || code === 'tfin') {
    return !details?.some(
      d =>
        d.type === 'refresh-token' &&
        d.value &&
        dayjs.utc(d.expires).isAfter(dayjs.utc()),
    )
  }

  if (code === 'upss') {
    const hasIdAndSecret =
      details?.some(d => d.type === 'client-id' && d.value) &&
      details?.some(d => d.type === 'client-secret' && d.value)
    return !hasIdAndSecret
  }

  return false
}
