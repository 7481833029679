import { DeferredProvisioningNotice } from './components/DeferredProvisioningNotice'
import { FedexLegacyCredForm } from './fxfe/FedexLegacyCredForm'
import { FedexLocationDetails } from './fxfe/FedexLocationDetails'
import { FedexProvisioning } from './fxfe/FedexProvisioning'
import { TForceProvisioning } from './upgf/TForceProvisioning'
import { UpsProvisioning } from './upgf/UpsProvisioning'
import {
  type BaseProvisionFormProps,
  type BaseProvisionLocationFormProps,
  type CredentailComponentContext,
  type CredentialFormProps,
} from './types'

type CarrierProvisionComponent = (props: BaseProvisionFormProps) => JSX.Element
type CarrierLocationProvisionComponent = (
  props: BaseProvisionLocationFormProps,
) => JSX.Element

type Context = CredentailComponentContext

export const provisionComponentProvider: Record<
  string,
  (context: Context) => CarrierProvisionComponent | null
> = {
  fxfe: (context: Context) => (context.beta ? FedexProvisioning : null),
}

export const provisionLocationComponentProvider: Record<
  string,
  (beta: boolean) => CarrierLocationProvisionComponent | null
> = {
  fxfe: (beta: boolean) => (beta ? FedexLocationDetails : null),
}

type CarrierCredentialComponent = (props: CredentialFormProps) => JSX.Element
export const carrierCredFormProvider: Record<
  string,
  (context: Context) => CarrierCredentialComponent | null
> = {
  fxfe: (context: Context) => (context.beta ? null : FedexLegacyCredForm),
  upss: (context: Context) =>
    context.onboarding ? DeferredProvisioningNotice : UpsProvisioning,
  upgf: (context: Context) =>
    context.onboarding ? DeferredProvisioningNotice : TForceProvisioning,
  tfin: (context: Context) =>
    context.onboarding ? DeferredProvisioningNotice : TForceProvisioning,
}
