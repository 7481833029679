import { useSearchParams } from 'react-router-dom'

import { FvLinkButton } from '@fv/client-components'
import { parseQueryString, stringifyQueryString } from '@fv/client-core'

import { type SortDirection } from './hooks/useShipmentListParams'

type SortDirectionToggleProps = { sortDirection: SortDirection }

export const SortDirectionToggle = ({
  sortDirection,
}: SortDirectionToggleProps) => {
  const [, setSearchParams] = useSearchParams()

  return (
    <FvLinkButton
      theme="callout"
      className="ml-1"
      onClick={() => {
        setSearchParams(p =>
          stringifyQueryString({
            ...parseQueryString(p.toString()),
            sortDirection: sortDirection === '1' ? '-1' : '1',
          }),
        )
      }}
    >
      {sortDirection === '1' ? 'ASC' : 'DESC'}
    </FvLinkButton>
  )
}
