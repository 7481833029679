/*
Utilities to obtain nested keys of an object
Found here: https://stackoverflow.com/questions/69095054/how-to-deep-flatten-a-typescript-interface-with-union-types-and-keep-the-full-ob

type AThing = {
  foo: {
    bar: string
  },
  root: boolean
}

type FlattenedThing = Flatten<AThing> = {
  'foo.bar': string
  'root': boolean
}

*/

import { getProperty, setProperty } from 'dot-prop'
import { Path } from 'react-hook-form'

import { Flatten, FlattenedKey } from '@fv/client-types'

export const getPathValue = <
  TInput extends object,
  TPath extends FlattenedKey<TInput>,
  TResult extends Flatten<TInput>[TPath] | undefined,
>(
  obj: TInput,
  path: TPath,
): TResult | undefined => {
  return getProperty(obj, path as string, undefined as TResult)
}

export const setPathValue = <TInput extends object, TPath extends Path<TInput>>(
  obj: TInput,
  path: TPath,
  value: unknown,
) => {
  setProperty(obj, path as string, value)
}

export const setPathValues = <
  TInput extends object,
  TValues extends Partial<Flatten<TInput>>,
>(
  obj: TInput,
  values: TValues,
) => {
  Object.keys(values).forEach(k =>
    setPathValue(obj, k as Path<TInput>, values[k as FlattenedKey<TInput>]),
  )
}

export const getPathValues = <
  TInput extends object,
  TKeys extends FlattenedKey<Partial<TInput>>[],
>(
  obj: TInput,
  keys: TKeys,
): Partial<Flatten<TInput>> => {
  return keys.reduce(
    (values, key) => ({
      ...values,
      [key]: getPathValue(obj, key),
    }),
    {},
  )
}

export const getPaths = <
  TObject extends object,
  TInput extends Flatten<TObject> | Partial<Flatten<TObject>>,
>(
  flattened: TInput,
) => {
  return Object.keys(flattened) as FlattenedKey<TObject>[]
}
