import dayjs from 'dayjs'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { FvButton } from '@fv/client-components'
import { type SelectFieldOption } from '@fv/client-types'

import { DateRangeSelector } from '../../../components/inputs/DateRangeSelector'
import {
  InputAdornment,
  InputGroup,
  InputGroupWrapper,
} from '../../../components/inputs/InputGroup'
import MultiSelectField from '../../../components/inputs/MultiSelectField'
import { SelectField } from '../../../components/inputs/SelectField'
import TextInput from '../../../components/inputs/TextInput'
import { useAccountFeatures } from '../../auth'
import { useCarrierOptions, useEquipmentOptions } from './hooks'
import { useAddContractedRates, useUpdateContractedRate } from './mutations'
import { type LocType, RateLocationForm } from './RateLocationForm'
import {
  type ContractedRate,
  type EquipmentTypeOption,
  type PartialContractedRate,
} from './types'

type ContractedRateFormProps = {
  rate?: ContractedRate
  onSubmit?: (carrierId: string) => void
  onCancel?: () => void
}

const getRateLocType = (rate?: ContractedRate): LocType => {
  if (rate?.destination.postalCodeEnd) return 'zip-range'
  if (rate?.destination.state) return 'state'
  return 'zip'
}

export const ContractedRateForm = ({
  rate,
  onSubmit,
  onCancel,
}: ContractedRateFormProps) => {
  const addRates = useAddContractedRates()
  const carriers = useCarrierOptions()
  const equipmentOptions = useEquipmentOptions()
  const { fuelAndDistance } = useAccountFeatures()
  const updateRate = useUpdateContractedRate()
  const isBusy = addRates.isLoading || updateRate.isLoading
  const rateTypeOptions: SelectFieldOption<
    PartialContractedRate['rate']['rateType']
  >[] = [{ text: 'Flat', value: 'flat' }]
  if (fuelAndDistance)
    rateTypeOptions.push({ text: 'Per mile', value: 'per-mile' })

  const form = useForm<PartialContractedRate>({
    ...(rate && {
      values: {
        ...rate,
        startDate: dayjs(rate.startDate).format('MM/DD/YYYY'),
        endDate: dayjs(rate.endDate).format('MM/DD/YYYY'),
      },
    }),
    ...(!rate && {
      defaultValues: {
        carrierId: carriers.data?.[0]?.value ?? '',
        rate: {
          serviceId: 'truckload',
        },
      },
    }),
  })
  const { register, control, handleSubmit, setValue, getValues, watch } = form
  const [isSelectingDates, setSelectingDates] = useState(false)
  const startDate = watch('startDate')
  const endDate = watch('endDate')

  const onValidSubmit = (partialRate: PartialContractedRate) => {
    const { endDate, startDate } = getValues()
    if (isBusy || !startDate || !endDate) return
    if (!partialRate.carrierId) {
      partialRate.carrierId = carriers.data?.[0]?.value ?? ''
    }

    if (!rate) {
      addRates
        .mutateAsync([partialRate])
        .then(() => onSubmit?.(partialRate.carrierId))
        .catch(e => {
          toast.error(e.message)
        })
    } else {
      updateRate
        .mutateAsync({
          ...rate,
          ...partialRate,
        })
        .then(() => onSubmit?.(partialRate.carrierId))
        .catch(e => {
          toast.error(e.message)
        })
    }
  }

  return (
    <form onSubmit={handleSubmit(onValidSubmit)}>
      <div className="form-row">
        <div className="form-group col-12">
          <label className="label" htmlFor="carrierId">
            Carrier
          </label>
          <SelectField
            {...register('carrierId', {})}
            placeholder="Select a carrier"
            required
            options={carriers.data}
          />
        </div>
      </div>
      <RateLocationForm initialType={getRateLocType(rate)} form={form} />
      <div className="form-row">
        <InputGroup
          className="w-2/12"
          label="Rate"
          required
          inputType="amount"
          startContent={<InputAdornment position="start" icon="dollar-sign" />}
          inputProps={{
            ...register('rate.amount', {
              valueAsNumber: true,
            }),
            isPrice: true,
          }}
        />

        <InputGroup
          className="w-2/12"
          label="Currency"
          required
          inputType="select"
          inputProps={{
            ...register('rate.currency'),
            options: [{ value: 'usd', text: 'USD' }],
          }}
        />
        <InputGroup
          className="w-4/12"
          label="Rate type"
          required
          inputType="select"
          inputProps={{
            ...register('rate.rateType'),
            options: rateTypeOptions,
          }}
        />

        <InputGroup
          className="w-2/12"
          label="Distance"
          inputType="amount"
          inputProps={{
            ...register('distance', {
              valueAsNumber: true,
            }),
          }}
        />

        <InputGroup
          className="w-2/12"
          label="Unit"
          inputType="select"
          inputProps={{
            ...register('distanceUOM'),
            options: [{ text: 'Miles', value: 'mi' }],
          }}
        />

        <InputGroupWrapper
          label="Contract start date"
          required
          name="startDate"
          className="w-1/4"
        >
          <InputAdornment icon="calendar-day" position="start" />

          <TextInput
            className="form-control"
            onFocus={() => setSelectingDates(true)}
            required
            {...register('startDate')}
          />
        </InputGroupWrapper>
        <InputGroupWrapper
          label="Contract end date"
          required
          name="startDate"
          className="w-1/4"
        >
          <InputAdornment icon="calendar-day" position="start" />

          <TextInput
            className="form-control"
            onFocus={() => setSelectingDates(true)}
            required
            {...register('endDate')}
          />
        </InputGroupWrapper>

        <InputGroup
          className="w-1/4"
          label="Contract number"
          inputType="text"
          inputProps={{
            ...register('contractNumber'),
          }}
        />
        <InputGroup
          className="w-1/4"
          label="Minimum rate"
          inputType="amount"
          inputProps={{
            isPrice: true,
            ...register('minimumRate', {
              valueAsNumber: true,
            }),
          }}
        />

        <InputGroupWrapper
          label="Equipment type"
          required
          name="equipmentTypes"
          className="w-10/12"
        >
          <Controller
            control={control}
            name="equipmentTypes"
            render={({ field }) => (
              <MultiSelectField
                // @ts-expect-error component not TS
                isSearchable
                menuPlacement="top"
                name={field.name}
                onChange={(nextEquipment: EquipmentTypeOption[]) => {
                  field.onChange(nextEquipment.map(e => e.value))
                }}
                options={equipmentOptions}
                required
                values={
                  field.value
                    ?.map(id => equipmentOptions.find(e => e.value === id))
                    .filter((e): e is EquipmentTypeOption => !!e) ?? []
                }
              />
            )}
          />
        </InputGroupWrapper>

        <InputGroup
          className="w-2/12"
          label="Fuel incl"
          inputType="select"
          inputProps={{
            options: [
              { text: 'Yes', value: 'true' },
              { text: 'No', value: 'false' },
            ],
            ...register('fuelIncluded'),
          }}
        />
      </div>

      <hr />

      <div className="flex !justify-end">
        <FvButton theme="plain" onClick={onCancel} type="button" icon="times" />

        <FvButton
          type="submit"
          icon="cloud-upload"
          theme="default"
          loading={isBusy}
        >
          Save contracted rate
        </FvButton>
      </div>
      <DateRangeSelector
        closeDatePicker={() => setSelectingDates(false)}
        dateRange={{
          from: startDate ? dayjs(startDate).toDate() : undefined,
          to: endDate ? dayjs(endDate).toDate() : undefined,
        }}
        endLabel="contract end date"
        isOpen={isSelectingDates}
        rangeLabel="Contract valid"
        setDateRange={v => {
          setValue('startDate', dayjs(v.from).format('MM/DD/YYYY'))
          setValue('endDate', dayjs(v.to).format('MM/DD/YYYY'))
        }}
        startLabel="contract start date"
      />
    </form>
  )
}
