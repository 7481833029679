import upperFirst from 'lodash/upperFirst'
import { useState } from 'react'

import { Icon, ManualTrackForm } from '@fv/client-components'
import { getCurrentSequence } from '@fv/client-core'
import { type EquipmentType, type FullShipment } from '@fv/client-types'

import { useUpdateTrackStatus } from '../../features/track/useUpdateTrackStatus'
import { useTypeNames } from '../../hooks/settings'
import { UpdateTrackingSequenceForm } from './UpdateTrackingSequenceForm'

type UpdateTrackingPanelProps = {
  closePanel: () => void
  load: FullShipment
}

const UpdateTrackingPanel = ({
  closePanel,
  load,
}: UpdateTrackingPanelProps) => {
  const { isLegacy, loadId, locations, tracking } = load
  const { equipmentName } = useTypeNames()
  const [nextSequence, setNextSequence] = useState('')
  const currentSequence = getCurrentSequence(tracking, isLegacy)
  const updateTrackStatus = useUpdateTrackStatus(loadId)
  const trackSequence = nextSequence || String(currentSequence)
  const truckSummary = composeSummary(load, equipmentName)

  return (
    <div className="shipment-list-ancillary-details-ctn">
      <nav className="divided-content divided-content--start shipment-list-ancillary-details__nav">
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            closePanel()
          }}
        >
          <Icon icon="arrow-to-left" />
          <span>Back</span>
        </a>
      </nav>
      <div className="shipment-list-ancillary-details overflow-auto">
        <p className="mb-0 flex flex-nowrap items-start">
          <Icon className="fa-fw color-dark" icon="truck" transform="down-2" />
          <span>{truckSummary}</span>
        </p>

        {!nextSequence ? (
          <>
            <hr className="mx-0 my-2 mb-8" />
            <UpdateTrackingSequenceForm
              currentSequence={Number(trackSequence)}
              locations={locations}
              setSequence={setNextSequence}
            />
          </>
        ) : (
          <>
            <hr className="mx-0 my-2" />
            <ManualTrackForm
              closeForm={closePanel}
              initialSequence={Number(nextSequence)}
              loading={updateTrackStatus.isLoading}
              locations={locations}
              onSave={updateTrackStatus.mutate}
            />
          </>
        )}
      </div>
    </div>
  )
}

function composeRefNums({ refNums }: FullShipment) {
  let refNumsDisplay = ''

  if (refNums?.length > 0) {
    if (refNumsDisplay) refNumsDisplay += ' / '
    refNumsDisplay += `Ref# ${refNums.map(n => n.value).join(' / ')}`
  }

  return refNumsDisplay
}

function composeSummary(
  load: FullShipment,
  getEquipmentName: (key: EquipmentType) => string,
) {
  const { equipment, selectedQuote, workflow } = load
  const hasName = workflow === 'truckload'
  const name = hasName
    ? getEquipmentName(selectedQuote?.equipmentType ?? equipment.type)
    : ''

  const equipmentDescription = equipment.description
    ? upperFirst(equipment.description)
    : ''

  const refNumsDisplay = composeRefNums(load)
  const noDescriptionDescription =
    hasName || equipmentDescription || refNumsDisplay
      ? ''
      : 'No description given'

  return [refNumsDisplay, name, equipmentDescription, noDescriptionDescription]
    .filter(v => !!v)
    .join(' / ')
}

export default UpdateTrackingPanel
