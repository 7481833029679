import { type ChangeEvent } from 'react'
import { create } from 'zustand'

import { type EquipmentType, type FullShipment } from '@fv/client-types'

import { type CarrierService } from '../../components/carrier-service-types/hooks'
import { type RadioFieldElement } from '../../components/inputs/RadioField'
import { type SpotQuoteContact } from '../spot-quote-contacts/types'
import { type SlimCarrier } from './useCarrierList'

type BaseManualRate = {
  carrierId: string
  carrierName: string
  price: string
  quoteNumber: string
  rateType: ManualRateType
}

export type ManualCarrierRate = BaseManualRate & {
  days: string
  serviceType: string
  rateType: 'manual-rate'
  serviceId: string
}

export type ManualSpotQuote = BaseManualRate & {
  carrierEmail: string
  contact: SpotQuoteContact | null
  equipmentType: EquipmentType
  rateType: 'spot-quote'
}

const initialManualRate = {
  carrierId: '',
  carrierName: '',
  price: '',
  quoteNumber: '',
}

const initialCarrierRate: ManualCarrierRate = {
  ...initialManualRate,
  days: '',
  serviceType: 'Standard',
  rateType: 'manual-rate',
  serviceId: 'standard',
}

const initialSpotQuote: ManualSpotQuote = {
  ...initialManualRate,
  carrierEmail: '',
  contact: null,
  equipmentType: 'ltl',
  rateType: 'spot-quote',
}

type RateFormActions = {
  actions: {
    handleCarrierChange: (
      carrierOrInputValue: SlimCarrier | string | null,
      formEl?: HTMLFormElement | null,
    ) => void
    handleServiceChange: (
      serviceType: CarrierService | null | undefined,
    ) => void
    handleChange: (
      event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void
    handleContactChange: (
      contactOrInputValue: SpotQuoteContact | string | null,
      formEl?: HTMLFormElement | null,
    ) => void
    initializeForm: (options: {
      load: FullShipment
      onError?: (loadId: string) => void
      onSuccess?: (loadId: string, quoteId: string) => void
      openSlider?: boolean
      rateType?: ManualRateType
    }) => void
    reset: () => void
    setRateType: (event: ChangeEvent<RadioFieldElement<ManualRateType>>) => void
  }
}

export type ManualRateType = 'manual-rate' | 'spot-quote'

export type RateFormState = {
  isOpen: boolean
  load: FullShipment | null
  manualRate: ManualCarrierRate
  onError: ((loadId: string) => void) | null
  onSuccess: ((loadId: string, quoteId: string) => void) | null
  rateType: ManualRateType
  spotQuote: ManualSpotQuote
}

const initialState: RateFormState = {
  isOpen: false,
  load: null,
  manualRate: initialCarrierRate,
  onError: null,
  onSuccess: null,
  rateType: 'spot-quote',
  spotQuote: initialSpotQuote,
}

export const useManualRateForm = create<RateFormActions & RateFormState>(
  (set, get) => ({
    ...initialState,
    actions: {
      handleServiceChange(service: CarrierService | null | undefined) {
        return set(prev => ({
          ...prev,
          manualRate: {
            ...prev.manualRate,
            serviceType: service?.name ?? '',
            serviceId: service?.id ?? '',
          },
        }))
      },
      handleCarrierChange: (carrierOrInputValue, formEl) => {
        const fieldReset = { carrierId: '', carrierName: '' }
        const serviceReset = { serviceType: '', serviceId: '' }

        if (typeof carrierOrInputValue === 'string') {
          return set(prev => ({
            manualRate: {
              ...prev.manualRate,
              ...fieldReset,
              ...serviceReset,
              carrierName: carrierOrInputValue,
            },
          }))
        }

        if (!carrierOrInputValue) {
          return set(prev => ({
            manualRate: {
              ...prev.manualRate,
              ...fieldReset,
              ...serviceReset,
            },
          }))
        }

        set(prev => {
          return {
            manualRate: {
              ...prev.manualRate,
              ...serviceReset,
              carrierId: carrierOrInputValue.id,
              carrierName: carrierOrInputValue.name,
            },
          }
        })

        // Let react-select value update before blurring input
        setTimeout(() => {
          const inputEl = formEl?.elements.namedItem('days')
          if (inputEl) (inputEl as HTMLInputElement).focus()
        }, 200)
      },
      handleChange: e => {
        const rateKey =
          get().rateType === 'manual-rate' ? 'manualRate' : 'spotQuote'

        set(prev => ({
          [rateKey]: {
            ...prev[rateKey],
            [e.target.name]: e.target.value,
          },
        }))
      },
      handleContactChange: (contactOrInputValue, formEl) => {
        const fieldReset: Partial<ManualSpotQuote> = {
          carrierEmail: '',
          carrierId: '',
          carrierName: '',
          contact: null,
        }

        if (typeof contactOrInputValue === 'string') {
          return set(prev => ({
            spotQuote: {
              ...prev.spotQuote,
              ...fieldReset,
              carrierEmail: contactOrInputValue,
            },
          }))
        }

        if (!contactOrInputValue) {
          return set(prev => ({
            spotQuote: {
              ...prev.spotQuote,
              ...fieldReset,
            },
          }))
        }

        const { carrierId, carrierName, email } = contactOrInputValue
        const name = carrierName?.trim()

        set(prev => ({
          spotQuote: {
            ...prev.spotQuote,
            ...(name && { carrierName: name }),
            carrierEmail: email?.trim() ?? '',
            carrierId: carrierId ?? '',
            contact: contactOrInputValue,
          },
        }))

        const nextInputName = name ? 'quoteNumber' : 'carrierName'
        const inputEl = formEl?.elements.namedItem(nextInputName)
        if (inputEl) (inputEl as HTMLInputElement).focus()
      },
      initializeForm: ({
        load,
        onError,
        onSuccess,
        openSlider = true,
        rateType = 'spot-quote',
      }) => {
        set(prev => ({
          ...(onError && { onError }),
          ...(onSuccess && { onSuccess }),
          ...(rateType === 'spot-quote' && {
            spotQuote: {
              ...prev.spotQuote,
              equipmentType: load.equipment.type,
            },
          }),
          isOpen: openSlider,
          load,
          rateType,
        }))
      },
      reset: () => set(initialState),
      setRateType: e => {
        const load = get().load

        if (!load || load.isLegacy || load.workflow !== 'ltl') {
          return set({ rateType: 'spot-quote' })
        }

        set({ rateType: e.target.value })
      },
    },
  }),
)
