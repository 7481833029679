import { type ChangeEvent, useMemo } from 'react'

import { Icon, Tooltip } from '@fv/client-components'
import { type EquipmentType, type ListShipment } from '@fv/client-types'

import CheckboxField from '../../components/inputs/CheckboxField'
import MessageField from '../../components/messaging/MessageField'
import MessageList from '../../components/messaging/MessageList'
import { Price } from '../../components/shared/Price'
import { useUnreadMessageIds } from '../../hooks/messages'
import { useTypeNames } from '../../hooks/settings'
import { type QuoteRequestResponse } from '../../hooks/shipments/useQuoteRequestResponses'
import { useRetractTender } from '../../hooks/shipments/useRetractTender'
import { usePermissions } from '../auth'
import { CarrierSelectForm } from './CarrierSelectForm'
import { RetractTenderForm } from './RetractTenderForm'

type AltEquipmentTypeIconProps = {
  alternateTypes?: EquipmentType[]
  quotedEquipmentType?: EquipmentType
}

const AltEquipmentTypeIcon = ({
  alternateTypes = [],
  quotedEquipmentType,
}: AltEquipmentTypeIconProps) => {
  const { equipmentName } = useTypeNames()
  const altTypeIndex = quotedEquipmentType
    ? alternateTypes?.indexOf(quotedEquipmentType) ?? -1
    : -1

  if (!quotedEquipmentType || altTypeIndex < 0) return null

  return (
    <Tooltip label={equipmentName(quotedEquipmentType)}>
      <span className="legend-icon legend-icon--tooltip">
        A{altTypeIndex + 1}
      </span>
    </Tooltip>
  )
}

type MiniQuoteListItemProps = {
  awardedQuoteId?: string
  isActive: boolean
  isChecked: boolean
  load: ListShipment
  quote: QuoteRequestResponse
  readOnly?: boolean
  setActiveQuoteId: (id: string | undefined) => void
  setSelectedQuoteId: (id: string | undefined) => void
}

export const MiniQuoteListItem = ({
  awardedQuoteId,
  isActive,
  isChecked,
  load,
  quote,
  readOnly = false,
  setActiveQuoteId,
  setSelectedQuoteId,
}: MiniQuoteListItemProps) => {
  const { canBook } = usePermissions()
  const { equipment, loadId, status: loadStatus } = load

  const { amount, carrierId, currency, id: quoteId, method, name } = quote

  const { canRetract } = useRetractTender()
  const unreadMessageIds = useUnreadMessageIds({ carrierId, loadId })
  const hasUnreadMessages = unreadMessageIds.length > 0
  const isLoadCanceled = loadStatus === 'canceled'
  const isLoadAwarded = loadStatus === 'awarded'
  const isQuoteSelected = quoteId === awardedQuoteId
  const showRetender = isLoadAwarded && !isQuoteSelected
  const showRetract = useMemo(
    () => isChecked && canRetract(load) && isQuoteSelected,
    [isChecked, canRetract, load, isQuoteSelected],
  )

  const showAward = useMemo(() => {
    if (
      !isChecked ||
      isLoadCanceled ||
      loadStatus === 'delivered' ||
      loadStatus === 'picked-up' ||
      showRetract
    )
      return false

    return loadStatus === 'pending' || !isQuoteSelected
  }, [isLoadCanceled, isChecked, loadStatus, showRetract, isQuoteSelected])

  const label = isQuoteSelected ? (
    <>
      <Icon icon="trophy" className="color-dark" />
      <span>{name}</span>
    </>
  ) : (
    name
  )

  return (
    <li className="standard-list__item">
      <div className="flex items-center">
        {(readOnly || !canBook) && (
          <div className="checkbox flex-1">{label}</div>
        )}

        {canBook && !readOnly && (
          <CheckboxField
            className="checkbox flex-1"
            checked={isChecked}
            disabled={!amount}
            label={label}
            labelClassName="limit-characters"
            name={quoteId}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target
              // Can't deselect awarded quote
              if (!checked && isQuoteSelected) return
              setSelectedQuoteId(checked ? quoteId : awardedQuoteId)
            }}
          />
        )}

        <AltEquipmentTypeIcon
          alternateTypes={equipment.alternateTypes}
          quotedEquipmentType={quote.equipmentType}
        />

        <div className="flex flex-nowrap items-center">
          {Boolean(amount) && (
            <Price
              className="shipment-list-pricing__price"
              amount={amount}
              currency={currency}
            />
          )}

          <div className="relative">
            {hasUnreadMessages && (
              <div className="shipment-list-pricing__unread-indicator" />
            )}

            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                setActiveQuoteId(isActive ? undefined : quoteId)
              }}
            >
              <Icon
                className="fa-fw"
                icon={hasUnreadMessages ? 'comment-alt-dots' : 'comment-alt'}
              />
            </a>
          </div>
        </div>
      </div>

      {showRetract && <RetractTenderForm loadId={loadId} />}

      {showAward && (
        <CarrierSelectForm
          onCancel={() => {
            setSelectedQuoteId(awardedQuoteId)
          }}
          retender={showRetender}
          load={load}
          quote={quote}
        />
      )}

      {isActive && (
        <div className="messages-ctn--shipment-list">
          <MessageField carrierId={carrierId} loadId={loadId} />

          <MessageList
            carrierId={carrierId}
            isApiMessages={method === 'api'}
            loadId={loadId}
          />
        </div>
      )}
    </li>
  )
}
