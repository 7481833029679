import InfoBox from '../../../components/InfoBox'
import { type CredentialFormProps } from '../types'

export const DeferredProvisioningNotice = ({
  carrier,
}: CredentialFormProps) => {
  return (
    <InfoBox icon="thumbs-up">
      After the onboarding process, we'll walk you through connecting your{' '}
      {carrier.name} account.
    </InfoBox>
  )
}
