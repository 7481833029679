import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Icon } from '@fv/client-components'

import { useUnreadMessageIds } from '../../hooks/messages'
import MessageField from '../messaging/MessageField'
import MessageList from '../messaging/MessageList'

const InactiveCarrierListItem = ({
  carrier,
  hasNewActivity,
  isActive,
  load,
  setActiveQuoteId,
}) => {
  const { loadId } = load
  const { carrierId, id, name, userId } = carrier
  const unreadMessageIds = useUnreadMessageIds({ carrierId, loadId })
  const hasUnreadMessages = unreadMessageIds.length > 0

  return (
    <li className="standard-list__item">
      <div className="flex items-center">
        <div>{name}</div>
        <div className="divided-content divided-content--start ml-auto flex">
          <div className="!relative">
            {hasUnreadMessages && (
              <div className="shipment-list-pricing__unread-indicator" />
            )}

            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                setActiveQuoteId(isActive ? null : id)
              }}
            >
              <Icon
                className={clsx({ pulse: hasNewActivity })}
                icon={hasUnreadMessages ? 'comment-alt-dots' : 'comment-alt'}
              />
            </a>
          </div>
        </div>
      </div>

      {isActive && (
        <div className="mt-4">
          <MessageField carrierId={carrierId} loadId={loadId} userId={userId} />
          <MessageList carrierId={carrierId} loadId={loadId} userId={userId} />
        </div>
      )}
    </li>
  )
}

InactiveCarrierListItem.propTypes = {
  carrier: PropTypes.object.isRequired,
  hasNewActivity: PropTypes.bool,
  isActive: PropTypes.bool,
  load: PropTypes.shape({
    loadId: PropTypes.string.isRequired,
  }).isRequired,
  setActiveQuoteId: PropTypes.func.isRequired,
}

export default InactiveCarrierListItem
