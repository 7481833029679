import { useQueryClient } from '@tanstack/react-query'
import orderBy from 'lodash/orderBy'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  FvButton,
  Icon,
  SliderPanel,
  useFvNavigate,
} from '@fv/client-components'
import { type DetailedAccountProvisioningDto } from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import { List, ListHeaderActions } from '../../components/List'
import { Loading } from '../../components/Loading'
import { HasPermission } from '../auth'
import { SettingsSection } from '../settings/SettingsSection'
import { AccountCarrierForm } from './AccountCarrierForm'
import { AccountCarrierListItem } from './AccountCarrierListItem'
import { AddCarrierContent } from './AddCarrierContent'
import { AccountCarrierLocationForm } from './LocationForm'
import { useRemoveCarrier } from './mutations'
import { useAccountCarriers } from './queries'

export const AccountCarriersPage = () => {
  const query = useAccountCarriers()
  const [showPanel, setShowPanel] = useState(false)
  const removeCarrier = useRemoveCarrier()
  const params = useParams()
  const navigate = useFvNavigate()
  const carrierId = params['carrierId']
  const locationId = params['locationId']
  const editingCarrier = {
    carrierId,
    locationId,
  }

  const closePanel = () => {
    navigate('/settings/carriers')
    setShowPanel(false)
  }

  const queryClient = useQueryClient()
  const handleSuccess = () => {
    queryClient.invalidateQueries(['account-carriers'])
    closePanel()
  }
  const handleDeleteClick = async (carrier: DetailedAccountProvisioningDto) => {
    if (
      window.confirm(
        `Are you sure you want to remove ${carrier.carrierName} from your account?`,
      )
    ) {
      await removeCarrier.mutateAsync({ carrierId: carrier.carrierId })
    }
  }

  if (query.isLoading && !query.data) {
    return (
      <div className="p-8">
        <Loading />
      </div>
    )
  }

  return (
    <div className="settings-main">
      {!query.data?.length && (
        <InfoBox className="mb-8" custom>
          <div className="flex gap-x-1">
            <Icon
              icon="badge-check"
              transform="down-3 d grow-5"
              className="text-fv-orange"
            />
            <p>
              Welcome to Freightview. You are currently on our settings page,
              where you can add carriers, customer addresses, products,
              spot-quote contacts, and more to customize our app. Let's begin by
              adding any carriers from which you wish to receive rates.
              Alternatively, you can choose another action from the settings
              menu or navigate through our app.
            </p>
          </div>
        </InfoBox>
      )}
      <SettingsSection title="Carriers">
        <HasPermission name="manage">
          <ListHeaderActions>
            <FvButton
              icon="plus"
              iconClass="fa-fw"
              onClick={() => setShowPanel(true)}
            >
              Add carrier
            </FvButton>
          </ListHeaderActions>
        </HasPermission>
        {query.data && (
          <List>
            {orderBy(query.data, 'carrierStatus').map(c => (
              <AccountCarrierListItem
                carrier={c}
                key={c.carrierId}
                onEditClick={locId => {
                  let rte = `/settings/carriers/${c.carrierId}`

                  if (locId) {
                    rte += `/${locId}`
                  }

                  navigate(rte)
                }}
                onDeleteClick={() => handleDeleteClick(c)}
              />
            ))}
          </List>
        )}
      </SettingsSection>

      <SliderPanel
        className="flex flex-col"
        isOpen={showPanel || !!editingCarrier.carrierId}
        closePanel={closePanel}
      >
        {editingCarrier.locationId && editingCarrier.carrierId && (
          <AccountCarrierLocationForm
            locationId={editingCarrier.locationId}
            carrierId={editingCarrier.carrierId}
            onCancel={closePanel}
            onSuccess={handleSuccess}
          />
        )}
        {!editingCarrier.locationId && editingCarrier.carrierId && (
          <AccountCarrierForm
            editing
            carrierId={editingCarrier.carrierId}
            locationId={editingCarrier.locationId}
            onCancel={closePanel}
            onSuccess={handleSuccess}
          />
        )}

        {!editingCarrier.carrierId && (
          <AddCarrierContent onSuccess={handleSuccess} />
        )}
      </SliderPanel>
    </div>
  )
}
