export const chargeNames = [
  'airport pickup',
  'camp site pickup',
  'construction site pickup',
  'fair pickup',
  'farm pickup',
  'golf pickup',
  'grocery pickup',
  'holiday pickup',
  'hospital pickup',
  'hotel pickup',
  'indian reserve pickup',
  'inside pickup',
  'liftgate pickup',
  'limited access pickup',
  'mall pickup',
  'military pickup',
  'mine pickup',
  'pier pickup',
  'place of worship pickup',
  'prison pickup',
  'residential pickup',
  'restaurant pickup',
  'retirement pickup',
  'saturday pickup',
  'school pickup',
  'self storage pickup',
  'stadium pickup',
  'sunday pickup',
  'terminal dropoff',
  'tradeshow pickup',
  'utility pickup',
  'arrival notice',
  'arrival schedule',
  'airport delivery',
  'camp site delivery',
  'construction site delivery',
  'fair delivery',
  'farm delivery',
  'golf delivery',
  'grocery delivery',
  'holiday delivery',
  'hospital delivery',
  'hotel delivery',
  'indian reserve delivery',
  'inside delivery',
  'liftgate delivery',
  'limited access delivery',
  'mall delivery',
  'military delivery',
  'mine delivery',
  'pier delivery',
  'place of worship delivery',
  'prison delivery',
  'residential delivery',
  'restaurant delivery',
  'retirement delivery',
  'saturday delivery',
  'school delivery',
  'self storage delivery',
  'stadium delivery',
  'sunday delivery',
  'terminal pickup',
  'tradeshow delivery',
  'utility delivery',
  'border processing fee',
  'certified pickup',
  'collect on delivery',
  'combined accessorials',
  'compliance surcharge',
  'customs broker',
  'deficit charge',
  'discount',
  'expedited',
  'ferry surcharge',
  'food grade protection',
  'fuel',
  'fuel surcharge',
  'guaranteed',
  'hazardous',
  'high cost area surcharge',
  'insurance',
  'international',
  'linear foot rule',
  'linehaul',
  'minimum charge',
  'other',
  'over dimension',
  'pallet',
  'protect from freezing',
  'security inspection',
  'single shipment',
  'sort and segregate',
  'sufferance warehouse delivery',
  'weight adjustment',
  'additional handling',
  'adult signature required',
  'base rate',
  'basic by appointment',
  'basic',
  'delivery confirmation',
  'hold at location',
  'home delivery premium',
  'indirect signature required',
  'insured value',
  'schedule pickup',
  'premium',
  'residential',
  'signature required',
  'standard',
  'california compliance surcharge',

  // new TL
  'driver-assist',
  'schedule',
  'notify',

  // freightview custom
  'freightview markup',
] as const

export const chargeCodes = [
  'MNC',
  'APT',
  'BPF',
  'CEP',
  'COD',
  'CCS',
  'CSD',
  'CSP',
  'BRF',
  'DSC',
  'EXP',
  'FAD',
  'FAP',
  'FER',
  'FGP',
  'FSC',
  'GRS',
  'HAZ',
  'HCD',
  'HOL',
  'HOP',
  'IDL',
  'IPU',
  'INS',
  'INT',
  'LGD',
  'LGP',
  'LAD',
  'LAP',
  'LFR',
  'LNH',
  'GVD',
  'GVP',
  'MIC',
  'OTR',
  'OVR',
  'PLT',
  'PRD',
  'PRP',
  'PFF',
  'RED',
  'REP',
  'SAD',
  'SAP',
  'SCD',
  'SCP',
  'IAC',
  'SSF',
  'SEG',
  'SUF',
  'SUD',
  'SUP',
  'TMD',
  'TMP',
  'EBD',
  'EBP',
  'EVS',
  'WGT',
  'PSP',
  'PSD',

  // Freightview Custom
  'CMB',
  'FVMU',
  'BSR',
  'ON_CALL_PICKUP',
] as const

export type ChargeCode = (typeof chargeCodes)[number]
export type ChargeName = (typeof chargeNames)[number]

export const charges: Array<{ code: ChargeCode; name: ChargeName }> = [
  { code: 'MNC', name: 'arrival notice' },
  { code: 'APT', name: 'arrival schedule' },
  { code: 'BPF', name: 'border processing fee' },
  { code: 'CEP', name: 'certified pickup' },
  { code: 'CMB', name: 'combined accessorials' },
  { code: 'COD', name: 'collect on delivery' },
  { code: 'CCS', name: 'compliance surcharge' },
  { code: 'CCS', name: 'california compliance surcharge' },
  { code: 'CSD', name: 'construction site delivery' },
  { code: 'CSP', name: 'construction site pickup' },
  { code: 'BRF', name: 'customs broker' },
  { code: 'DSC', name: 'discount' },
  { code: 'EXP', name: 'expedited' },
  { code: 'FAD', name: 'farm delivery' },
  { code: 'FAP', name: 'farm pickup' },
  { code: 'FER', name: 'ferry surcharge' },
  { code: 'FGP', name: 'food grade protection' },
  { code: 'FSC', name: 'fuel' },
  { code: 'FSC', name: 'fuel surcharge' },
  { code: 'GRS', name: 'guaranteed' },
  { code: 'HAZ', name: 'hazardous' },
  { code: 'HCD', name: 'high cost area surcharge' },
  { code: 'HOL', name: 'holiday delivery' },
  { code: 'HOP', name: 'holiday pickup' },
  { code: 'IDL', name: 'inside delivery' },
  { code: 'IPU', name: 'inside pickup' },
  { code: 'INS', name: 'insurance' },
  { code: 'INT', name: 'international' },
  { code: 'LGD', name: 'liftgate delivery' },
  { code: 'LGP', name: 'liftgate pickup' },
  { code: 'LAD', name: 'limited access delivery' },
  { code: 'LAP', name: 'limited access pickup' },
  { code: 'LFR', name: 'linear foot rule' },
  { code: 'LNH', name: 'linehaul' },
  { code: 'GVD', name: 'military delivery' },
  { code: 'GVP', name: 'military pickup' },
  { code: 'MIC', name: 'minimum charge' },
  { code: 'OTR', name: 'other' },
  { code: 'OVR', name: 'over dimension' },
  { code: 'PLT', name: 'pallet' },
  { code: 'PRD', name: 'pier delivery' },
  { code: 'PRP', name: 'pier pickup' },
  { code: 'PSD', name: 'prison delivery' },
  { code: 'PSP', name: 'prison pickup' },
  { code: 'PFF', name: 'protect from freezing' },
  { code: 'RED', name: 'residential delivery' },
  { code: 'REP', name: 'residential pickup' },
  { code: 'SAD', name: 'saturday delivery' },
  { code: 'SAP', name: 'saturday pickup' },
  { code: 'SCD', name: 'school delivery' },
  { code: 'SCP', name: 'school pickup' },
  { code: 'IAC', name: 'security inspection' },
  { code: 'SSF', name: 'single shipment' },
  { code: 'SEG', name: 'sort and segregate' },
  { code: 'SUF', name: 'sufferance warehouse delivery' },
  { code: 'SUD', name: 'sunday delivery' },
  { code: 'SUP', name: 'sunday pickup' },
  { code: 'TMD', name: 'terminal dropoff' },
  { code: 'TMP', name: 'terminal pickup' },
  { code: 'EBD', name: 'tradeshow delivery' },
  { code: 'EBP', name: 'tradeshow pickup' },
  { code: 'EVS', name: 'insured value' },
  { code: 'WGT', name: 'weight adjustment' },

  // freightview custom
  { code: 'FVMU', name: 'freightview markup' },
  { code: 'BSR', name: 'base rate' },
]

export const chargeNameFromCode = (code: ChargeCode): ChargeName => {
  return charges.find(v => v.code === code)?.name
}
