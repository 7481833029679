import { type ChangeEvent, useCallback } from 'react'

import { type LoadNotificationType } from '@fv/client-types'

import CheckboxField from '../../components/inputs/CheckboxField'
import { InputGroup } from '../../components/inputs/InputGroup'
import { quoteFuncs } from '../quote/quoteFuncs'
import { processsLoadNotifications } from './bookFuncs'
import { useBookingFormCtx } from './BookingFormProvider'

const validateNotifications = (e: ChangeEvent<HTMLInputElement>) => {
  const processed = processsLoadNotifications({
    email: e.target.value,
    notificationTypes: [],
  })
  if (processed && processed.length > 5) {
    e.target.setCustomValidity('Please limit FedEx shares to 5 emails max')
    e.target.reportValidity()
  } else {
    e.target.setCustomValidity('')
  }
}
const loadNotificationOptions: Array<{
  id: LoadNotificationType
  text: string
}> = [
  { id: 'shipment', text: 'Ship' },
  { id: 'tender', text: 'Tendered' },
  { id: 'exception', text: 'Exception' },
  { id: 'estimated-delivery', text: 'Estimated Delivery' },
  { id: 'delivery', text: 'Delivery' },
]

export const FedExShipAlert = () => {
  const quote = useBookingFormCtx(s => s.quote)
  const actions = useBookingFormCtx(s => s.actions)
  const notifications = useBookingFormCtx(s => s.notifications)

  const toggleNotificationType = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const nType = e.target.value as LoadNotificationType
      actions.updateNotifications({
        email: notifications?.email ?? '',
        notificationTypes: e.target.checked
          ? [...(notifications?.notificationTypes ?? []), nType]
          : notifications?.notificationTypes.filter(t => t !== nType) ?? [],
      })
    },
    [actions, notifications],
  )
  const updateNotificationEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      validateNotifications(e)
      actions.updateNotifications({
        notificationTypes: notifications?.notificationTypes ?? [],
        email: e.target.value,
      })
    },
    [actions, notifications],
  )

  if (!quoteFuncs.isFedex(quote)) return null

  return (
    <div className="border-fv-gray -mt-8 mb-8 border bg-fv-gray-100 p-4">
      <InputGroup
        label="FedEx ShipAlert® (optional)"
        inputType="text"
        inputProps={{
          value: notifications?.email ?? '',
          onChange: updateNotificationEmail,
          name: 'notifications',
          placeholder: 'bill@example.com, ted@example.com',
        }}
      />
      <div className="form-group flex">
        {loadNotificationOptions.map(t => (
          <div className="pr-4" key={t.id}>
            <CheckboxField
              name={`notifications_${t.id}`}
              checked={
                notifications?.notificationTypes?.includes(t.id) ?? false
              }
              label={t.text}
              value={t.id}
              onChange={toggleNotificationType}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
