import { UsernamePasswordForm } from './components/UsernamePasswordForm'
import { carrierCredFormProvider } from './CarrierComponentProvider'
import { type CredentialFormProps } from './types'

export const AccountCarrierCredentialForm = (
  props: CredentialFormProps,
): JSX.Element => {
  const { beta, onboarding } = props
  const CarrierComp = carrierCredFormProvider[props.carrier.code]?.({
    beta,
    onboarding,
  })
  return (
    <div>
      {!CarrierComp && <UsernamePasswordForm {...props} />}
      {CarrierComp && <CarrierComp {...props} />}
    </div>
  )
}
