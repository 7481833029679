import dayjs from 'dayjs'
import { useState } from 'react'

import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import { DayPickerPanel } from '../dates/DayPickerPanel'

type DatePickerProps = {
  onClose: () => void
  value?: string
  onSelect: (d: string) => void
  isOpen: boolean
}

const dateFormat = 'ddd MMM D, YYYY'

type InputProps = {
  className: string
  isBusy: boolean
  invoiceDate: string
  setInvoiceDate: (d: string) => void
}

export const DatePickerInput = ({
  className,
  isBusy,
  invoiceDate,
  setInvoiceDate,
}: InputProps) => {
  const [showDatepicker, setShowDatepicker] = useState(false)

  return (
    <>
      <InputGroup
        className={className}
        inputProps={{
          name: 'invoiceDate',
          onChange: undefined,
          placeholder: 'Invoice date',
          onFocus: () => setShowDatepicker(true),
          readOnly: true,
          disabled: isBusy,
          value: invoiceDate ? dayjs(invoiceDate).format(dateFormat) : '',
        }}
        inputType="text"
        startContent={<InputAdornment position="start" icon="calendar-day" />}
      />
      <DatePickerPanel
        isOpen={showDatepicker}
        value={invoiceDate}
        onClose={() => setShowDatepicker(false)}
        onSelect={date => {
          setInvoiceDate(date ? dayjs(date).toISOString() : '')
          setShowDatepicker(false)
        }}
      />
    </>
  )
}

const DatePickerPanel = ({
  onClose,
  value,
  onSelect,
  isOpen,
}: DatePickerProps) => {
  const hasClear = !!value
  const date = value ? dayjs(value).toDate() : undefined

  return (
    <DayPickerPanel
      selected={date}
      showClear={hasClear}
      open={isOpen}
      onClose={onClose}
      mode="single"
      onSelect={d => {
        onSelect(d?.toISOString() ?? '')
      }}
    />
  )
}
