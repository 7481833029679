import { useQueryClient } from '@tanstack/react-query'
import { type ReactNode, useCallback } from 'react'
import toast from 'react-hot-toast'

import {
  type LiveEvent,
  useLiveEventsProcessor,
  useNavState,
} from '@fv/client-components'

import { provisioningQueryKeys } from '../features/account-carriers/queries'
import { addressBookKeys } from '../features/addresses/useAddressBookAddresses'
import { notificationKey } from '../features/notifications/useNotification'
import { quoteRequestKeys } from '../features/quote-request/useQuoteRequest'
import { inProcessShipmentKeys } from '../features/shipment-list/hooks/useInProcessShipments'
import { systemNotificationQueryKeys } from '../features/system-notifications/queries'
import { newMessageId } from '../hooks/messages'
import { quoteKeys } from '../hooks/shipments/useQuotes'
import { useRateModifier } from '../hooks/useRateModifier'

const timeout = 1500
type InvalidationTimeouts = {
  [key: string]: ReturnType<typeof setTimeout>
}

const addRemoveTimeoutKey = 'shipment-add-remove'
const invalidationTimeouts: InvalidationTimeouts = {}
const shipmentsTimeoutKey = 'shipments'
const shipmentsQueryKey = [shipmentsTimeoutKey]
const unreadCountTimeoutKey = 'unread-notification-count'
const unreadCountQueryKey = [unreadCountTimeoutKey]

export const LiveEventsHandler = ({
  authenticateUri,
  liveEventsUri,
}: {
  authenticateUri?: string
  liveEventsUri?: string
}): ReactNode => {
  const queryClient = useQueryClient()
  const rateMod = useRateModifier()
  const notifyRefresh = useNavState(s => s.notifyRefresh)

  const handleEvent = useCallback(
    (event: LiveEvent) => {
      const eventName: string = event.event

      if (eventName === 'account:carriers:update') {
        queryClient.invalidateQueries(['audit-settings'])
        queryClient.invalidateQueries(provisioningQueryKeys.account)
      }

      if (eventName === 'account:users:notification') {
        queryClient.setQueriesData<{ unreadCount: number } | undefined>(
          unreadCountQueryKey,
          prev => ({
            unreadCount: (prev?.unreadCount ?? 0) + 1,
          }),
        )
        queryClient.invalidateQueries(notificationKey)
      }

      if (eventName === 'account:users:remove-urgent-notification') {
        clearTimeout(invalidationTimeouts[unreadCountTimeoutKey])

        invalidationTimeouts[unreadCountTimeoutKey] = setTimeout(() => {
          queryClient.invalidateQueries(unreadCountQueryKey)
        }, timeout)

        queryClient.invalidateQueries(notificationKey)
      }

      if (eventName === 'account:users:notifications:reset-count') {
        queryClient.setQueriesData(unreadCountQueryKey, { unreadCount: 0 })
        queryClient.invalidateQueries(notificationKey)
      }

      if (eventName === 'addressbook:update') {
        queryClient.invalidateQueries(addressBookKeys.all)
      }

      if (eventName === 'export:done') {
        toast.success('Shipment list export complete, please check your email.')
      }

      if (eventName === 'message:update') {
        const { loadId, message } = event.data

        queryClient.setQueriesData<{ _id: string }[] | undefined>(
          ['messages', loadId],
          prev =>
            prev
              ?.filter(m => m._id !== message._id && m._id !== newMessageId)
              ?.concat(message),
        )
      }

      if (eventName === 'quote:remove') {
        const { loadId, quote } = event.data

        queryClient.setQueriesData<{ _id: string }[] | undefined>(
          quoteKeys.load(loadId, quote.loadQuoteVersion),
          prev => prev?.filter(q => q._id !== quote._id),
        )
      }

      if (eventName === 'quote:add') {
        const { loadId, quote, workflow } = event.data
        const markedUp = rateMod(quote, workflow)

        queryClient.setQueriesData<{ _id: string }[] | undefined>(
          quoteKeys.load(loadId, quote.loadQuoteVersion),
          prev =>
            prev?.some(p => p._id === quote._id) // if we already have the quote (if the update comes through before the add) don't insert it
              ? prev
              : [markedUp, ...(prev ? prev : [])],
        )
      }

      if (eventName === 'quote:update') {
        const { loadId, workflow, quote } = event.data
        const markedUp = rateMod(quote, workflow)

        queryClient.setQueriesData<{ _id: string }[] | undefined>(
          quoteKeys.load(loadId, quote.loadQuoteVersion),
          prev => prev?.filter(q => q._id !== quote._id)?.concat(markedUp),
        )
      }

      if (
        eventName === 'shipper:shipment:add' ||
        eventName === 'shipper:shipment:remove'
      ) {
        clearTimeout(invalidationTimeouts[addRemoveTimeoutKey])

        invalidationTimeouts[addRemoveTimeoutKey] = setTimeout(() => {
          queryClient.invalidateQueries(inProcessShipmentKeys.all)
          queryClient.invalidateQueries(['shipments', 'search'])
        }, timeout)
      }

      if (eventName === 'shipper:shipment:update') {
        const { loadId, quoteRequestId } = event.data

        // All shipment lists will be invalidated here
        clearTimeout(invalidationTimeouts[addRemoveTimeoutKey])
        clearTimeout(invalidationTimeouts[loadId])
        clearTimeout(invalidationTimeouts[shipmentsTimeoutKey])

        invalidationTimeouts[loadId] = setTimeout(() => {
          queryClient.invalidateQueries(['load', loadId])
          queryClient.invalidateQueries(
            quoteRequestKeys.request(quoteRequestId),
          )
        }, timeout)

        invalidationTimeouts[shipmentsTimeoutKey] = setTimeout(() => {
          queryClient.invalidateQueries(inProcessShipmentKeys.bookingErrors())
          queryClient.invalidateQueries(shipmentsQueryKey)
        }, timeout)
      }

      if (eventName === 'spot-quote-recipients:update') {
        const { loadId } = event.data
        const queryKey = ['spot-quote-recipients', loadId]
        const timeoutKey = queryKey.join(':')

        clearTimeout(invalidationTimeouts[timeoutKey])

        invalidationTimeouts[timeoutKey] = setTimeout(() => {
          queryClient.invalidateQueries(queryKey)
        }, timeout)
      }

      if (eventName === 'system:reset') {
        notifyRefresh()
      }

      if (eventName === 'system:notification') {
        queryClient.invalidateQueries(systemNotificationQueryKeys.all)
      }
    },
    [queryClient, rateMod, notifyRefresh],
  )

  useLiveEventsProcessor({
    authenticateUri,
    handleEvent,
    liveEventsUri,
  })

  return null
}
