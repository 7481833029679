import { useMutation, useQueryClient } from '@tanstack/react-query'
import chunk from 'lodash/chunk'
import pluralize from 'pluralize'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri, supportMessage } from '../../../constants'
import { contractedRatesKeys, fuelTableKeys } from './queries'
import {
  type ContractedRate,
  type FuelTable,
  type FuelTableEntry,
  type PartialContractedRate,
} from './types'

const MAX_UPLOADS = 20

async function addContractedRates(
  rates: PartialContractedRate[],
): Promise<ContractedRate[]> {
  const batchCount = Math.ceil(rates.length / MAX_UPLOADS)
  const batches: PartialContractedRate[][] = []
  const endpoint = `${apiUri}/contracted-rates/many`
  const responses: Array<{
    errorMessage?: { message: string }
    json: ContractedRate[]
    ok: boolean
  }> = []

  for (let i = 0; i < batchCount; i++) {
    const startIndex = i * MAX_UPLOADS
    const batch = rates.slice(startIndex, startIndex + MAX_UPLOADS)
    batches.push(batch)
  }

  for (const batch of batches) {
    const options = buildFetchOptionsWithAuth({
      body: JSON.stringify({ rates: batch }),
      method: 'POST',
    })

    const response = await fetchJson(endpoint, options)
    responses.push(response as (typeof responses)[number])
  }

  if (responses.every(r => r.ok)) {
    return responses.map(r => r.json).flat()
  }

  const errorResponse = responses.find(r => !r.ok)

  throw (
    errorResponse?.errorMessage ??
    new Error(`Unable to upload contracted rates, ${supportMessage}`)
  )
}

export function useAddContractedRates() {
  const queryClient = useQueryClient()

  return useMutation(addContractedRates, {
    onSuccess: rates => {
      // In case duplicates were updated instead of added
      queryClient.invalidateQueries(
        contractedRatesKeys.carrier(rates[0].carrierId),
      )

      toast.success(
        `Contracted ${pluralize('rate', rates.length)} ${
          !rates.length ? 'updated' : 'added'
        } successfully.`,
      )
    },
  })
}

async function removeContractedRates(rateIds: string[]) {
  const endpoint = `${apiUri}/contracted-rates`

  for (const chunkedRates of chunk(rateIds, 1000)) {
    const options = buildFetchOptionsWithAuth({
      body: JSON.stringify(chunkedRates),
      method: 'DELETE',
    })

    const response = await fetchJson(endpoint, options)

    if (!response.ok) throw response.errorMessage
  }

  return rateIds
}

export function useRemoveContractedRates(carrierId?: string) {
  const queryClient = useQueryClient()

  return useMutation(removeContractedRates, {
    onSuccess: rateIds => {
      queryClient.setQueryData(
        contractedRatesKeys.carrier(carrierId),
        (prev?: ContractedRate[]) => {
          if (!prev) return []
          return prev.filter(r => !rateIds.includes(r._id))
        },
      )

      toast.success(
        `Contracted ${pluralize('rate', rateIds.length)} removed successfully.`,
      )
    },
  })
}

async function updateContractedRate(
  rate: ContractedRate,
): Promise<ContractedRate> {
  const endpoint = `${apiUri}/contracted-rates/${rate._id}`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify(rate),
    method: 'PUT',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useUpdateContractedRate() {
  const queryClient = useQueryClient()

  return useMutation(updateContractedRate, {
    onSuccess: rate => {
      queryClient.setQueryData(
        contractedRatesKeys.carrier(rate.carrierId),
        (prev?: ContractedRate[]) => {
          if (!prev) return [rate]
          return prev.map(r => (r._id === rate._id ? rate : r))
        },
      )

      toast.success('Contracted rate updated successfully.')
    },
  })
}

async function extendContractedRates(dto: {
  endDate: Date
  carrierId?: string
  postalCode?: string
  contractId?: string
}): Promise<boolean> {
  const { endDate, carrierId, postalCode, contractId } = dto
  const endpoint = `${apiUri}/contracted-rates/update-dates`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      endDate,
      ...(carrierId && { carrierId }),
      ...(postalCode && { postalCode }),
      ...(contractId && { contractId }),
    }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.ok
  throw response.errorMessage
}

export function useExtendContractedRates(carrierId?: string) {
  const queryClient = useQueryClient()

  return useMutation(extendContractedRates, {
    onSettled: (res, error) => {
      if (error) {
        toast.error('Unable to extend contracted rates.')
        return
      }

      queryClient.invalidateQueries(contractedRatesKeys.carrier(carrierId))
      toast.success('Contracted rates extended successfully.')
    },
  })
}

async function addFuelTable(dto: {
  carrierId: string | null
  rows: FuelTableEntry[]
}): Promise<FuelTable> {
  const { carrierId, rows } = dto
  const endpoint = `${apiUri}/contracted-rates/fuel`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({ carrierId, rows }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json

  throw (
    response.errorMessage ??
    new Error(`Unable to upload fuel table, ${supportMessage}`)
  )
}

export function useAddFuelTable(carrierId: string | null) {
  const queryClient = useQueryClient()

  return useMutation(addFuelTable, {
    onSuccess: fuelTable => {
      queryClient.setQueryData(fuelTableKeys.carrier(carrierId), fuelTable)
      if (!fuelTable.carrierId) queryClient.invalidateQueries(fuelTableKeys.all)
      toast.success(`Fuel table uploaded successfully.`)
    },
  })
}

async function removeFuelTable(dto: {
  accountLevel: boolean
  fuelTableId: string
}): Promise<boolean> {
  const endpoint = `${apiUri}/contracted-rates/fuel/${dto.fuelTableId}`
  const options = buildFetchOptionsWithAuth({ method: 'DELETE' })
  const response = await fetchJson(endpoint, options)

  if (response.ok) return dto.accountLevel

  throw (
    response.errorMessage ??
    new Error(`Unable to remove fuel table, ${supportMessage}`)
  )
}

export function useRemoveFuelTable(carrierId: string | null) {
  const queryClient = useQueryClient()

  return useMutation(removeFuelTable, {
    onSuccess: (accountLevel: boolean) => {
      queryClient.setQueryData(fuelTableKeys.carrier(carrierId), null)

      if (accountLevel) queryClient.invalidateQueries(fuelTableKeys.all)
      else queryClient.invalidateQueries(fuelTableKeys.carrier(carrierId))

      toast.success(`Fuel table removed successfully.`)
    },
  })
}
