import { useRef } from 'react'
import { type SelectInstance } from 'react-select'

import { FvButton } from '@fv/client-components'
import { strip } from '@fv/client-core'
import { type PackagingType } from '@fv/client-types'

import {
  InputAdornment,
  InputGroup,
  InputGroupWrapper,
} from '../../../components/inputs/InputGroup'
import { RadioField } from '../../../components/inputs/RadioField'
import { type YesNoToggleProps } from '../../../components/inputs/YesNoInput'
import {
  useFreightClassOptions,
  useSecondaryPackageOptions,
} from '../../../hooks/settings/useEnumOptions'
import { isCarrierPackaging } from '../../../utils/isCarrierPackaging'
import { HasFeature } from '../../auth'
import { getWorkflowProductMode } from '../commodityUtils'
import { PackagingSelector } from '../PackagingSelector'
import { ProductCatalogButton } from '../ProductCatalog'
import { ProductCatalogTypeahead } from '../ProductCatalogTypeahead'
import {
  type ProductCatalogInit,
  useProductCatalog,
} from '../useProductCatalog'
import { catalogToItemData, defaultLoadContainsItem } from './loadItemHelpers'
import { useItemsState } from './loadItemsContext'
import {
  type DetailedFieldProps,
  type DetailedItemFieldProps,
  type HandlingUnitFieldProps,
  type HandlingUnitType,
  type ItemFieldProps,
  unitTypeOptions,
} from './types'

export const DescriptionField = ({ register, ...props }: ItemFieldProps) => {
  const desc = register('description')
  return (
    <InputGroup
      {...props}
      inputProps={desc}
      inputType="text"
      locked={desc.readOnly}
      label={props.label ?? 'Description'}
    />
  )
}

export const DimensionsField = ({ register, ...props }: ItemFieldProps) => {
  const width = register('width')
  const height = register('height')
  const length = register('length')
  return (
    <div className={props.className}>
      <InputGroup
        className="basis-1/3"
        inputProps={length}
        inputType="amount"
        label="L (in)"
        locked={length.readOnly}
        required={length.required}
      />
      <InputGroup
        className="basis-1/3"
        inputProps={width}
        inputType="amount"
        label="W (in)"
        locked={width.readOnly}
        required={width.required}
      />

      <InputGroup
        helpText="Dimensions are optional. If you supply any dims, we require that you enter all three."
        className="basis-1/3 whitespace-nowrap"
        inputProps={height}
        inputType="amount"
        label="H (in)"
        locked={height.readOnly}
        required={height.required}
      />
    </div>
  )
}

export const PackagingField = ({
  register,
  value,
  onChange,
  carrierCode,
  ...props
}: DetailedFieldProps & {
  carrierCode?: string
}) => {
  const { workflow } = useItemsState()
  const packageType = register('type')
  return (
    <InputGroup
      className={props.className}
      inputProps={{
        name: packageType.name,
      }}
      inputType="custom"
      label="Packaging"
      locked={packageType.readOnly}
      required
    >
      <PackagingSelector
        allowCustom
        length={value.length}
        carrierCode={carrierCode}
        disabled={packageType.disabled}
        workflow={workflow}
        name={packageType.name}
        value={value.type ?? ''}
        width={value.width}
        onChange={val => {
          onChange({
            ...value,
            type: val.type,
            length: val.lengthNum,
            width: val.widthNum,
            ...(isCarrierPackaging(val.type) && {
              width: undefined,
              height: undefined,
              length: undefined,
              nonStandard: false,
            }),
          })
        }}
      />
    </InputGroup>
  )
}

export const ProductCatalogField = ({
  className,
  doublesAsDescription,
  onChange,
  value,
  label,
  init,
  required,
  afterSelect,
}: Omit<DetailedItemFieldProps, 'register'> & {
  disabled?: boolean
  doublesAsDescription?: boolean
  init?: Partial<ProductCatalogInit>
  required?: boolean
  afterSelect?: () => void
}) => {
  const { workflow, disabled } = useItemsState()
  const search = useProductCatalog(s => s.search)
  const searchRef = useRef<SelectInstance>(null)
  const searchText = useProductCatalog(s => s.searchText)
  return (
    <InputGroupWrapper
      className={className}
      label={label ?? 'Search'}
      name="productSearch"
      required={required}
    >
      {!doublesAsDescription && (
        <InputAdornment position="start">
          <FvButton
            fw
            theme="default"
            icon="search"
            iconClass="color-success"
            onClick={() => {
              searchRef.current?.openMenu('first')
              searchRef.current?.focus()

              if (searchRef.current?.hasValue()) {
                setTimeout(() => {
                  searchRef.current?.focusedOptionRef?.click()
                })
              }
            }}
          />
        </InputAdornment>
      )}
      <ProductCatalogTypeahead
        filter={{
          mode: getWorkflowProductMode(workflow),
          ...init?.filter,
        }}
        required={required}
        name="productSearch"
        hasDropdownIcon={!doublesAsDescription}
        onInputChange={v => {
          if (doublesAsDescription) {
            onChange({ ...value, description: v })
          } else {
            search(v)
          }
        }}
        searchRef={searchRef}
        setProduct={product => {
          if (disabled) return

          if (doublesAsDescription) {
            onChange({ ...value, description: product.description })
          } else {
            onChange(strip(catalogToItemData(product)))
          }

          search('')
          afterSelect?.()
        }}
        value={doublesAsDescription ? value.description ?? '' : searchText}
      />
      <div className="input-group__append">
        <ProductCatalogButton
          label="Catalog"
          disabled={disabled}
          init={{
            filter: {
              mode: getWorkflowProductMode(workflow),
            },
            setProduct: p => onChange(strip(catalogToItemData(p))),
            ...(init ?? {}),
          }}
        />
      </div>
    </InputGroupWrapper>
  )
}

export const DeclaredValueField = ({ register, ...props }: ItemFieldProps) => {
  const dv = register('declaredValue')
  return (
    <InputGroup
      {...props}
      inputProps={{ ...dv, min: 1 }}
      startContent={<InputAdornment position="start" icon="dollar-sign" />}
      label="Declared Value"
      inputType="amount"
      locked={dv.readOnly}
    />
  )
}

export const QuantityField = ({ register, ...props }: ItemFieldProps) => {
  const quantity = register('quantity')
  return (
    <InputGroup
      {...props}
      required={quantity.required}
      inputProps={quantity}
      inputType="amount"
      label="Quantity"
      locked={quantity.readOnly}
    />
  )
}

export const NmfcField = ({
  register,
  label = 'NMFC item #',
  ...props
}: ItemFieldProps) => {
  return (
    <InputGroup
      inputProps={register('fullNmfc')}
      helpText="Optional: If you know your NMFC item number, enter it here to put it on the bill of lading."
      inputType="text"
      label={label}
      {...props}
    />
  )
}

export const FreightClassField = ({
  label = 'Freight class',
  register,
  ...props
}: ItemFieldProps) => {
  const freightClassOptions = useFreightClassOptions()
  return (
    <InputGroup
      helpText="Freight class indicates the type of product in the shipment and affects not only carrying charges but also the overall shipping process. Rates returned may be limited if you do not provide a class."
      {...props}
      inputProps={{
        ...register('freightClass'),
        options: freightClassOptions,
      }}
      inputType="select"
      label={label}
    />
  )
}

export const WeightField = ({
  label = 'Weight',
  register,
  ...props
}: ItemFieldProps) => {
  const weight = register('weight')
  return (
    <InputGroup
      endContent={<InputAdornment label="lbs." />}
      inputType="amount"
      label={label}
      {...props}
      required={weight.required}
      inputProps={weight}
    />
  )
}

export const SecondaryPackageTypeField = ({
  parentPackageType,
  label = 'Units',
  register,
  ...props
}: ItemFieldProps & {
  parentPackageType?: PackagingType
}) => {
  const { workflow } = useItemsState()
  const unitOptions = useSecondaryPackageOptions(
    workflow,
    parentPackageType ?? 'pallet',
  )
  return (
    <InputGroup
      {...props}
      inputProps={{
        ...register('type'),
        options: unitOptions,
      }}
      label={label}
      inputType="select"
    />
  )
}

export const StackableField = ({
  register,
  ...props
}: HandlingUnitFieldProps) => {
  const stackable = register('stackable')
  return (
    <InputGroup
      inputProps={{
        ...stackable,
        type: 'radio',
      }}
      {...props}
      inputType="yes-no"
      label="Stackable?"
      locked={stackable.readOnly}
    />
  )
}

export const NonStandardField = ({
  register,
  ...props
}: HandlingUnitFieldProps) => {
  const nonStandard = register('nonStandard')
  return (
    <InputGroup
      {...props}
      inputProps={{
        ...nonStandard,
        type: 'radio',
      }}
      inputType="yes-no"
      label="Unboxed or Crated?"
      locked={nonStandard.readOnly}
    />
  )
}

export const IsHazardousField = ({
  register,
  type,
  ...props
}: ItemFieldProps & {
  type: YesNoToggleProps['type']
}) => {
  const isHazardous = register('isHazardous')
  return (
    <InputGroup
      {...props}
      inputType="yes-no"
      inputProps={{
        ...register('isHazardous'),
        type,
      }}
      label="Hazmat?"
      locked={isHazardous.readOnly}
    />
  )
}

export const HandlingUnitTypeField = ({
  register,
  onChange,
  value,
  ...props
}: DetailedFieldProps) => {
  const huType = register('handlingUnitType')
  const { step } = useItemsState()
  const handleChange = (v: HandlingUnitType) => {
    const needsConfirm = value.handlingUnitType === 'multi' && v === 'single'
    if (
      !needsConfirm ||
      window.confirm(
        'Switching back to a same commodity handling unit will clear out your commodity details.  Are you sure you want to proceed?',
      )
    ) {
      onChange({
        ...value,
        handlingUnitType: v,
        contains:
          v === 'multi' && step === 'quoting'
            ? [defaultLoadContainsItem(), defaultLoadContainsItem()]
            : [],
        hazardous: v === 'multi' ? undefined : value.hazardous,
        isHazardous: v === 'multi' ? false : value.isHazardous,
        weight: undefined,
        ...(v === 'multi' && {
          freightClass: undefined,
          quantity: 1,
        }),
        ...(v === 'single' && {
          quantity: undefined,
        }),
      })
    }
  }
  return (
    <HasFeature name="mixedPallets">
      <InputGroupWrapper
        name="unit-type"
        label="Handling unit type"
        className="basis-full"
        helpText="Will this handling unit have multiple freight classes?"
      >
        <div className="border-fv-blue-400 flex-1 border border-x-0 border-dashed py-2">
          <RadioField
            options={unitTypeOptions}
            value={huType.value?.toString() || 'single'}
            name={huType.name}
            onChange={e => handleChange(e.target.value)}
          />
        </div>
      </InputGroupWrapper>
    </HasFeature>
  )
}
