import { useMemo } from 'react'

import { Icon } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { supportMessage } from '../../constants'
import { useCurrency } from '../../hooks/settings'
import { useQuotes } from '../../hooks/shipments'
import { alphabeticalSorter } from '../../utils/alphabeticalSorter'
import InfoBox from '../InfoBox'

type QuoteHistoryPanelProps = {
  closePanel: () => void
  load: FullShipment
}

const QuoteHistoryPanel = ({ closePanel, load }: QuoteHistoryPanelProps) => {
  const quotesQuery = useQuotes(load.loadId)
  const priceParts = useCurrency()
  const data = quotesQuery.data
  const isLoading = quotesQuery.isLoading && quotesQuery.isFetching
  const isLoaded = !isLoading && !!data
  const hasError = !isLoading && !data

  const quotes = useMemo(() => {
    if (!data) return []

    return data
      .filter(
        q => q.amount > 0 && (q.status === 'active' || q.status === 'awarded'),
      )
      .sort((a, b) => {
        if (a.status === 'awarded') return -1
        if (b.status === 'awarded') return 1

        return a.amount - b.amount || alphabeticalSorter('providerName')(a, b)
      })
  }, [data])

  return (
    <div className="shipment-list-ancillary-details-ctn">
      <nav className="shipment-list-ancillary-details__nav divided-content divided-content--start">
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            closePanel()
          }}
        >
          <Icon icon="arrow-to-left" />
          <span>Back</span>
        </a>
        <div>
          <Icon icon="list" className="color-dark" />
          <span>Quote history</span>
        </div>
      </nav>
      <div className="shipment-list-ancillary-details overflow-auto">
        {isLoading && <InfoBox icon="sync">Loading...</InfoBox>}
        {hasError && <InfoBox>Unable to load quotes, {supportMessage}</InfoBox>}

        {isLoaded &&
          (quotes.length ? (
            <ul className="standard-list mb-8">
              {quotes.map(q => {
                const [dollars, cents] = priceParts(q.amount)

                return (
                  <li className="standard-list__item" key={q._id}>
                    <div className="flex items-center">
                      {q.status === 'awarded' ? (
                        <>
                          <Icon
                            className="color-dark"
                            icon="trophy"
                            transform="up-1"
                          />
                          <span>{q.providerName}</span>
                        </>
                      ) : (
                        q.providerName
                      )}

                      <div className="ml-auto flex flex-nowrap">
                        <span className="price">
                          <span className="price__dollars">${dollars}</span>
                          <span className="price__cents">{cents}</span>
                          <span className="price__currency">
                            {q.currency?.toUpperCase()}
                          </span>
                        </span>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          ) : (
            <InfoBox>No quotes available.</InfoBox>
          ))}
      </div>
    </div>
  )
}

export default QuoteHistoryPanel
