import { Loading } from '../../components/Loading'
import { provisionLocationComponentProvider } from './CarrierComponentProvider'
import { useAccountCarriers, useCarrierProvisioningDetails } from './queries'
import { type BaseProvisionLocationFormProps } from './types'

type Props = BaseProvisionLocationFormProps
export const AccountCarrierLocationForm = (props: Props) => {
  const { carrierId, onCancel, onSuccess, locationId } = props
  const { data: carrier } = useCarrierProvisioningDetails(carrierId)
  const { data: carriers } = useAccountCarriers()
  const accountCarrierData = carriers?.find(
    c => c.carrierId === props.carrierId,
  )

  if (!carrier || !accountCarrierData) {
    return <Loading />
  }

  const CarrierComponent = provisionLocationComponentProvider[
    carrier.code ?? ''
  ]?.(!!accountCarrierData.isBeta)
  if (CarrierComponent) {
    return (
      <CarrierComponent
        carrierId={carrierId}
        locationId={locationId}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />
    )
  }

  return null
}
