import { useState } from 'react'

import { FvButton, R, ValidatedForm } from '@fv/client-components'
import { useFormModel } from '@fv/client-core'

import { InputGroup } from '../../../components/inputs/InputGroup'
import { useCurrencyTypes } from '../../../hooks/settings/useAppSettings'
import { FedexError } from './FedexError'
import {
  useFxfeProvisionInitMutation,
  useFxfeProvisionValidateMutation,
  useFxfeSetStep,
  useFxfeToken,
  useFxfeWizardContext,
} from './hooks'
import {
  type FxfeMfaValidationRequestDto,
  type FxfeProvisionResponse,
  type MfaOptionItem,
} from './types'

export const FedexMfaInput = () => {
  const {
    selectedOption: [option],
  } = useFxfeWizardContext()

  if (option?.type === 'invoice') {
    return <InvoiceDetails />
  } else if (option?.type === 'support') {
    return <SupportNotice />
  }
  return <PinForm />
}

const pinStepSubtitleGenerator = (o: MfaOptionItem) => {
  if (o.type === 'call') {
    return `FedEx should be placing an automated call to ${o.value} that will share your PIN.`
  }
  if (o.type === 'sms') {
    return `FedEx should have texted a PIN to the phone number on the account: ${o.value}.`
  }
  if (o.type === 'email') {
    return `FedEx should have sent an email to ${o.value} that contains the PIN.`
  }
  return ''
}

const PinForm = () => {
  const setStep = useFxfeSetStep()
  const accountToken = useFxfeToken()
  const {
    selectedOption: [option],
  } = useFxfeWizardContext()
  const [model, updateModel] = useState<
    Pick<FxfeMfaValidationRequestDto, 'pin'>
  >({ pin: '' })
  const { register } = useFormModel({ value: model, onChange: updateModel })
  const [error, setError] = useState<FxfeProvisionResponse>()
  const {
    accountInfo: [wizardAccountInfo],
  } = useFxfeWizardContext()
  const mutation = useFxfeProvisionValidateMutation()
  const handleSubmit = async () => {
    const response = await mutation.mutateAsync({
      ...wizardAccountInfo,
      pin: model.pin,
      accountToken,
    })
    if (response.status === 'error') {
      setError(response)
    }
    if (response.status === 'ok') {
      setStep('success')
    }
  }
  if (!option) {
    return (
      <FedexError error="There was an error. Please start the process over." />
    )
  }

  return (
    <ValidatedForm onValidSubmit={handleSubmit}>
      <p>{pinStepSubtitleGenerator(option)}</p>
      <FedexError response={error} />
      <InputGroup
        label="Please enter your PIN:"
        inputType="text"
        inputProps={{
          ...register('pin'),
        }}
      />
      <div className="flex justify-end">
        <FvButton theme="plain" onClick={() => setStep('mfa-option')}>
          Back
        </FvButton>
        <FvButton theme="primary" type="submit" loading={mutation.isLoading}>
          Continue
        </FvButton>
      </div>
    </ValidatedForm>
  )
}

const InvoiceDetails = () => {
  const setStep = useFxfeSetStep()
  const accountToken = useFxfeToken()
  const [model, updateModel] = useState<
    Omit<FxfeMfaValidationRequestDto, 'accountToken'>
  >({ invoiceCurrency: 'usd' })
  const { register } = useFormModel({ value: model, onChange: updateModel })
  const [error, setError] = useState<FxfeProvisionResponse>()
  const currencyTypes = useCurrencyTypes()
  const {
    accountInfo: [wizardAccountInfo],
  } = useFxfeWizardContext()
  const mutation = useFxfeProvisionValidateMutation()
  const handleSubmit = async () => {
    const response = await mutation.mutateAsync({
      ...model,
      ...wizardAccountInfo,
      accountToken,
    })
    if (response.status === 'error') {
      setError(response)
    }
    if (response.status === 'ok') {
      setStep('success')
    }
  }
  return (
    <ValidatedForm onValidSubmit={handleSubmit}>
      <p>
        Please enter information from your latest FedEx
        <R /> Parcel invoice:
      </p>
      <FedexError response={error} />
      <div className="grid gap-x-2 lg:grid-cols-4">
        <InputGroup
          label="Invoice Number"
          inputType="text"
          inputProps={{
            ...register('invoiceNumber'),
          }}
        />
        <InputGroup
          label="Invoice Date"
          inputType="text"
          inputProps={{
            ...register('invoiceDate'),
          }}
        />
        <InputGroup
          label="Invoice Amount"
          inputType="amount"
          inputProps={{
            isPrice: true,
            ...register('invoiceAmount', { valueAsNumber: true }),
          }}
        />
        <InputGroup
          label="Invoice Currency"
          inputType="select"
          inputProps={{
            options: currencyTypes.map(v => ({
              text: v.name,
              value: v.key,
            })),
            ...register('invoiceCurrency'),
          }}
        />
      </div>

      <div className="flex justify-end">
        <FvButton theme="plain" onClick={() => setStep('mfa-option')}>
          Back
        </FvButton>
        <FvButton theme="primary" type="submit" loading={mutation.isLoading}>
          Continue
        </FvButton>
      </div>
    </ValidatedForm>
  )
}

const SupportNotice = () => {
  const setStep = useFxfeSetStep()
  const accountNumber = useFxfeToken()
  const {
    accountInfo: [accountInfo],
  } = useFxfeWizardContext()
  const mutation = useFxfeProvisionInitMutation()
  const [error, setError] = useState<FxfeProvisionResponse>()
  const handleSubmit = async () => {
    const response = await mutation.mutateAsync({
      accountNumber,
      ...accountInfo,
    })
    if (response.status === 'error') {
      setError(response)
    }
    if (response.status === 'ok') {
      setStep('success')
    }
  }
  return (
    <ValidatedForm onValidSubmit={handleSubmit}>
      <p>
        Reach out to FedEx
        <R /> support and inform them that you're trying to complete the multi
        factor authentication process with Freightview. Once they've confirmed
        your identity, click the button below to add FedEx to your account.
      </p>
      <FedexError response={error} />
      <div className="flex justify-end">
        <FvButton theme="plain" onClick={() => setStep('mfa-option')}>
          Back
        </FvButton>
        <FvButton theme="primary" type="submit" loading={mutation.isLoading}>
          Continue
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
