import { useState } from 'react'
import { Controller, type FieldPath, type UseFormReturn } from 'react-hook-form'

import { type ClientAddressLookupResult } from '@fv/client-types'
import { CountryMapper, states } from '@fv/models'

import {
  AddressInput,
  type AddressInputProps,
} from '../../../components/inputs/AddressInput'
import { InputGroupWrapper } from '../../../components/inputs/InputGroup'
import { StateSelector } from '../../../components/inputs/StateSelector'
import { TabLink, TabLinkList } from '../../../components/TabLink'
import { type AddressWithCompany } from '../../addresses/addressFuncs'
import { type PartialContractedRate } from './types'

const locTypes = ['zip', 'zip-range', 'state'] as const
export type LocType = (typeof locTypes)[number]
type Props = {
  form: UseFormReturn<PartialContractedRate, any, undefined>
  initialType: LocType
}

const locLabels: Record<LocType, string> = {
  'zip-range': 'Postal code to range of postal codes',
  zip: 'Postal code to postal code',
  state: 'Postal code to state',
}

export const RateLocationForm = ({ form, initialType }: Props) => {
  const [destType, setDestType] = useState<LocType>(initialType)
  const { setValue } = form
  const handleTypeChange = (loc: LocType) => {
    setDestType(loc)
    if (loc !== 'zip-range') {
      setValue('destination.postalCodeEnd', undefined)
    }
    if (loc !== 'state') {
      setValue('destination.state', undefined)
    }
  }
  return (
    <div className=" border-fv-blue-200 -mx-8 mb-4 border-t ">
      <div className="bg-fv-beer flex items-center gap-2 px-8 pb-3 pt-2">
        <span className="pt-1">Type of rate&nbsp;&gt;&nbsp;</span>
        <TabLinkList className="flex-wrap pt-1 xl:flex-nowrap">
          {locTypes.map(locType => (
            <TabLink
              key={locType}
              isActive={destType === locType}
              icon="dot-circle"
              onClick={() => handleTypeChange(locType)}
            >
              {locLabels[locType]}
            </TabLink>
          ))}
        </TabLinkList>
      </div>
      <div className="bg-fv-blue-100 border-fv-blue-200 grid grid-cols-2 border-b border-t px-8 pb-2 pt-4">
        <div className="pr-20">
          <RateLocationFormPart type="zip" part="origin" form={form} />
        </div>
        <div>
          <RateLocationFormPart
            part="destination"
            type={destType}
            form={form}
          />
        </div>
      </div>
    </div>
  )
}

type PartProps = Pick<Props, 'form'> & {
  part: 'origin' | 'destination'
  type: LocType
}
const RateLocationFormPart = ({ part, type, form }: PartProps) => {
  const { control, setValue } = form
  const postalCodeKey: FieldPath<PartialContractedRate> =
    part === 'origin' ? 'origin.postalCode' : 'destination.postalCode'
  const postalCodeEndKey: FieldPath<PartialContractedRate> =
    part === 'origin' ? 'origin.postalCodeEnd' : 'destination.postalCodeEnd'
  const countryKey: FieldPath<PartialContractedRate> =
    part === 'origin' ? 'origin.country' : 'destination.country'
  const stateKey: FieldPath<PartialContractedRate> =
    part === 'origin' ? 'origin.state' : 'destination.state'
  const labelPrefix = part === 'origin' ? 'Origin' : 'Destination'
  const label = (text: string) => `${labelPrefix} ${text}`

  const country = form.watch(countryKey)

  return (
    <div className="flex flex-1 gap-2">
      {type === 'zip' && (
        <InputGroupWrapper
          key={`${part}.zip-postal`}
          name={postalCodeKey}
          label={label('postal code')}
          className="flex-1"
          required
        >
          <Controller
            name={postalCodeKey}
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <PostalCodeInput
                name={name}
                onLocationChange={v => {
                  onChange(v?.postalCode)
                  setValue(countryKey, v?.country ?? 'us')
                }}
                location={{
                  postalCode: value,
                  country,
                }}
              />
            )}
          />
        </InputGroupWrapper>
      )}
      {type === 'zip-range' && (
        <InputGroupWrapper
          key={`${part}.range-postal`}
          name={postalCodeKey}
          label={label('postal code range')}
          labelClassName="whitespace-nowrap"
          required
        >
          <div className="flex items-center gap-2 lg:flex-row">
            <div className="w-5/12">
              <Controller
                name={postalCodeKey}
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <PostalCodeInput
                    name={name}
                    onLocationChange={v => {
                      onChange(v?.postalCode)
                      setValue(countryKey, v?.country ?? 'us')
                    }}
                    location={{
                      postalCode: value,
                      country,
                    }}
                  />
                )}
              />
            </div>
            <div>
              <div className="bg-fv-blue-300 flex h-8 w-8 items-center justify-center rounded-full p-1 text-lg">
                to
              </div>
            </div>
            <div className="w-5/12">
              <Controller
                name={postalCodeEndKey}
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <PostalCodeInput
                    name={name}
                    onLocationChange={v => onChange(v?.postalCode)}
                    location={{
                      postalCode: value,
                      country,
                    }}
                  />
                )}
              />
            </div>
          </div>
        </InputGroupWrapper>
      )}
      {type === 'state' && (
        <InputGroupWrapper
          key={stateKey}
          name={stateKey}
          label={label('state')}
          className="flex-1"
          labelClassName="whitespace-nowrap"
          required
        >
          <Controller
            control={control}
            name={stateKey}
            render={({ field: { ref, ...fieldProps } }) => {
              const found = states.find(
                v => v.abbreviation === fieldProps.value,
              )
              return (
                <StateSelector
                  {...fieldProps}
                  value={
                    found
                      ? `${found.name}, ${CountryMapper.toUpper(found.country)}`
                      : undefined
                  }
                  onChange={v => {
                    fieldProps.onChange(v?.abbreviation)
                    setValue(postalCodeKey, '')
                    setValue(postalCodeEndKey, '')
                    setValue(countryKey, v?.country ?? 'us')
                  }}
                />
              )
            }}
          />
        </InputGroupWrapper>
      )}
    </div>
  )
}

type PciProps = Pick<
  AddressInputProps,
  'name' | 'onLocationChange' | 'location'
> & {
  format?: (loc: AddressWithCompany) => string
}
const PostalCodeInput = (props: PciProps) => {
  const format = (x: AddressWithCompany) =>
    x.postalCode ? `${x.postalCode}, ${CountryMapper.toUpper(x.country)}` : ''
  return (
    <AddressInput
      context="truckload"
      cacheFormattedLocation={false}
      includeAddressBook // TODO: https://github.com/freightview/freightview/issues/21354 - change to not include address book values once server accepts that option
      filterOptions={dedupePostalCodes}
      formatOptionLabel={props.format ?? format}
      formatAddress={format}
      required
      {...props}
    />
  )
}
function dedupePostalCodes(
  options: Array<{ label: string; value: ClientAddressLookupResult }>,
) {
  return options.filter((o, i, list) => {
    return i === list.findIndex(opt => opt.label === o.label)
  })
}
