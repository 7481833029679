import { FvButton, Icon, R } from '@fv/client-components'

import { useFxfeWizardContext } from './hooks'

export const FedexMfaSuccess = () => {
  const { onComplete } = useFxfeWizardContext()
  return (
    <div>
      <div className="flex flex-col justify-center">
        <Icon icon="check-circle" className="text" size="10x" color="inherit" />
        <p className="mt-8">
          Your FedEx
          <R /> account is connected! If you have multiple company locations, be
          sure to enter the appropriate account numbers for each location by
          expanding the FedEx carrier on this page and entering the required
          information.
        </p>
      </div>
      <div className="flex justify-end">
        <FvButton theme="primary" onClick={onComplete}>
          Continue
        </FvButton>
      </div>
    </div>
  )
}
