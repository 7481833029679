import { useQueryClient } from '@tanstack/react-query'
import ObjectID from 'bson-objectid'
import dayjs from 'dayjs'
import { createContext, useContext, useState } from 'react'

import { useFvNavigate } from '@fv/client-components'
import {
  buildFetchOptionsWithAuth,
  defaultCloseTime,
  defaultOpenTime,
  fetchJson,
  stripPhoneNumberFormatting,
} from '@fv/client-core'
import {
  type ClientAddressLookupResult,
  type FullShipment,
  type ListShipment,
  type ShipmentLocation,
} from '@fv/client-types'
import { type Country } from '@fv/models'

import { apiUri, routes } from '../../constants'
import {
  type BookingFormContext,
  type LoadToBook,
  type ReferrerType,
} from '../../features/booking/types'
import { useQueryParam } from '../../hooks/settings'
import { useStaticDataQuery } from '../../hooks/useStaticDataQuery'
import { type AccountRecentCache } from '../../types/AccountRecentCache'
import { type FormLocation } from '../../types/FormLocation'
import { useAddresses } from '../addresses/useAddress'
import { defaultFormBillTo } from '../billTo/billToFuncs'
import { defaultFormBroker } from '../customsBroker/brokerFuncs'
import { defaultFormContact } from '../emergencyContact/contactFuncs'

export const bookingFormContext = createContext<BookingFormContext>({
  activateSection: () => null,
  billTo: defaultFormBillTo,
  bolNumber: '',
  setBolNumber: () => null,
  bookShipment: () => null,
  customsBrokers: [defaultFormBroker],
  emergencyContact: defaultFormContact,
  formSections: [],
  goToNextSection: () => null,
  hasPickupOnlyPrompt: false,
  isBooking: false,
  isPickupOnly: false,
  isRetender: false,
  loads: [],
  locations: [],
  onPickupOnlyChange: () => null,
  requireQuoteReason: false,
  selectedQuotes: [],
  setBillTo: () => null,
  setCustomsBrokers: () => null,
  setEmergencyContact: () => null,
  setFormRef: () => null,
  setFormSections: () => null,
  setLoads: () => null,
  setLocations: () => null,
  settings: {
    requiresDeclaredValue: true,
    requiresFullBOLDetails: true,
    allowTLPostalCodeChange: false,
  },
  tags: [],
  setTags: () => null,
  shareEmails: '',
  setShareEmails: () => null,
})

export function useBookingForm() {
  return useContext(bookingFormContext)
}

export function toFormLocation(
  shipmentLocation: ShipmentLocation,
  load: Pick<FullShipment, 'shares' | 'workflow'>,
  addresses?: ClientAddressLookupResult[],
): FormLocation {
  const {
    accessorials,
    address,
    address2,
    addressBookId,
    city,
    closesAt,
    company,
    contactEmail,
    contactName,
    contactPhone,
    contactPhoneExt,
    instructions,
    opensAt,
    postalCode,
    refNums = [],
    sequence,
    shipType,
    state,
    stopDate,
    type,
  } = shipmentLocation
  const addressBookEntry = addresses?.find(a => a._id === addressBookId)
  const useDefaultDockTime = type === 'origin' || load.workflow === 'truckload'
  const shares = load.shares
    ? load.shares.filter(s => s.sequence === sequence).map(s => s.email)
    : addressBookEntry?.bolFollowers ?? []
  const sendBOL = load.shares ? !!shares.length : !!addressBookEntry?.sendBOL

  return {
    ...shipmentLocation, // country, lat, lng, timezone, etc.
    accessorials: accessorials || [],
    address: address || '',
    address2: address2 || '',
    city: city || '',
    closesAt: closesAt || (useDefaultDockTime ? defaultCloseTime : undefined),
    company: company || '',
    contactEmail: contactEmail || '',
    contactName: contactName || '',
    contactPhone: stripPhoneNumberFormatting(contactPhone || ''),
    contactPhoneExt: contactPhoneExt || '',
    instructions: instructions || '',
    opensAt: opensAt || (useDefaultDockTime ? defaultOpenTime : undefined),
    postalCode: postalCode || '',
    refNum: refNums[0]?.value || '',
    shipType: shipType || '',
    state: state || '',
    stopDate: stopDate ? dayjs.utc(stopDate).toDate() : null,

    copyBOL: sendBOL,
    shares: shares.join(', '),

    id: new ObjectID().toString(),
  }
}

export function toLocationDTO(formLoc: FormLocation): ShipmentLocation {
  const { accountLocationId, addressBookId, shipType, ...location } = formLoc

  return {
    ...location,
    ...(accountLocationId && { accountLocationId }),
    ...(addressBookId && { addressBookId }),
    ...(shipType && { shipType }),
    address: formLoc.address.trim(),
    address2: formLoc.address2.trim(),
    city: formLoc.city.trim(),
    company: formLoc.company.trim(),
    contactEmail: formLoc.contactEmail.trim() || undefined,
    contactName: formLoc.contactName.trim() || undefined,
    contactPhone: formLoc.contactPhone.trim() || undefined,
    contactPhoneExt: formLoc.contactPhoneExt.trim() || undefined,
    country: formLoc.country.trim().toLowerCase() as Country,
    instructions: formLoc.instructions.trim(),
    postalCode: formLoc.postalCode.trim(),
    // @ts-expect-error type is 'other'
    refNums: [
      {
        type: 'other',
        value: formLoc.refNum.trim(),
      },
    ].filter(n => Boolean(n.value)),
    state: formLoc.state.trim(),
    stopDate: formLoc.stopDate ? dayjs.utc(formLoc.stopDate).format() : null,
    stopType: formLoc.stopType,
  }
}

export function useLocations(loadsToBook: LoadToBook[]) {
  const load = loadsToBook[0]
  const addressQuery = useAddresses(loadsToBook[0])
  const [locations, setLocations] = useState(() =>
    load.locations.map(l => toFormLocation(l, load, addressQuery.data)),
  )

  return {
    locations,
    setLocations,
  }
}

const fetchRecentAccountData = async (): Promise<AccountRecentCache> => {
  const endpoint = `${apiUri}/accounts/settings/recent`
  const response = await fetchJson(endpoint, buildFetchOptionsWithAuth())
  if (response.ok) return response.json
  throw response.errorMessage
}

const recentAccountDataKey = 'recent-account-data'

export const useRecentAccountData = (enabled = true) => {
  return useStaticDataQuery([recentAccountDataKey], fetchRecentAccountData, {
    enabled,
  })
}

export const useMutateRecentAccountData = () => {
  const queryClient = useQueryClient()

  return {
    clear: () => queryClient.invalidateQueries([recentAccountDataKey]),
  }
}

export const useBookingPageNavigation = () => {
  const navigate = useFvNavigate()
  const referrer = useQueryParam<ReferrerType>('ref')

  const leave = (
    loads: Pick<ListShipment, 'status' | 'loadId' | 'quoteRequestId'>[],
    returnToReferrer = false,
  ) => {
    // when editing (not creating) a multi truck load - loads.length = 1.
    // so shipper will be taken back to listing or details currently - expected?
    const firstLoad = loads[0]
    const { loadId, status, quoteRequestId } = firstLoad
    if (loads.length > 1 && quoteRequestId) {
      return navigate(`/search?text=${quoteRequestId}`)
    }

    if (!returnToReferrer || (returnToReferrer && referrer === 'details')) {
      return navigate(routes.details(loadId))
    }

    const isPending = status === 'pending' || status === 'awarded'
    const qs = `load=${loadId}`
    return navigate(isPending ? `/pending?${qs}` : `/active?${qs}`)
  }
  return {
    leave,
  }
}
