import clsx from 'clsx'

import { Icon } from '@fv/client-components'
import { type ListShipment } from '@fv/client-types'

import { ShipmentCardWorkflowLabel } from '../../features/shipment-list/ShipmentCardWorkflowLabel'
import { useCurrency } from '../../hooks/settings'
import { useRateModifier } from '../../hooks/useRateModifier'
import NewMessageIndicator from './NewMessageIndicator'

type PendingShipmentCardHeaderProps = {
  shipment: ListShipment
  active?: boolean
}

const PendingShipmentCardHeader = ({
  shipment,
  active,
}: PendingShipmentCardHeaderProps) => {
  const {
    cheapestQuote,
    isLiveLoad,
    messageSummary,
    pickup,
    selectedQuote,
    status,
    workflow,
  } = shipment

  const priceParts = useCurrency()
  const rateMod = useRateModifier()
  const quote = selectedQuote ?? cheapestQuote
  const displayQuote = quote ? rateMod(quote, workflow) : undefined
  const [dollars, cents] = priceParts(displayQuote?.amount)
  const currency = displayQuote?.currency?.toUpperCase()
  const isAwarded = status === 'awarded'
  const isPending = status === 'pending'

  const isNewMessageNull =
    NewMessageIndicator({ messageSummary, active }) === null

  return (
    <>
      <div className="shipment-card__tab divided-content divided-content--start">
        <div className="col-auto">
          <ShipmentCardWorkflowLabel
            mode={shipment.equipment.mode}
            isMultiStop={shipment.locations.length > 2}
          />
        </div>

        <div className="limit-characters flex items-center">
          <span
            className={clsx('circle-icon mr-2', {
              'circle-icon--awarded': isAwarded && pickup?.status !== 'error',
              'circle-icon--success': isPending,
              'circle-icon--warning': pickup?.status === 'error',
            })}
          >
            {isAwarded &&
              (pickup?.status === 'error' ? (
                <Icon
                  icon="exclamation-triangle"
                  className="color-dark"
                  transform="shrink-2 up-1 right-.5"
                />
              ) : (
                <Icon
                  icon="trophy"
                  className="color-dark"
                  transform="shrink-1 down-1 right-.5"
                />
              ))}

            {isPending && <Icon icon="dollar-sign" className="color-dark" />}
          </span>
          <span className="limit-characters">
            {isAwarded && 'Awarded - '}

            {isAwarded &&
              (pickup?.status === 'error'
                ? 'booking failed'
                : 'awaiting confirmation')}

            {isPending && 'Receiving quotes'}
          </span>
        </div>
        {workflow === 'truckload' && (
          <div className="shipment-card__live-load whitespace-nowrap">
            <Icon
              className={clsx({ 'color-dark': isLiveLoad })}
              icon={isLiveLoad ? 'boxes' : 'list'}
            />
            <span>{isLiveLoad ? 'Live load' : 'Quote only'}</span>
          </div>
        )}
      </div>

      <div
        className={clsx('shipment-card__lowest-quote price', {
          'not-available': !displayQuote,
          '!border-t-fv-orange relative z-20': active,
          'border-r-fv-orange': isNewMessageNull && active,
        })}
      >
        {displayQuote ? (
          <span className="price">
            <span className="price__dollars">${dollars}</span>
            <span className="price__cents">{cents}</span>
            <span className="price__currency">{currency}</span>
          </span>
        ) : (
          <span title="No quotes received">NA</span>
        )}
      </div>

      <NewMessageIndicator messageSummary={messageSummary} active={active} />
    </>
  )
}

export default PendingShipmentCardHeader
