import { type MouseEvent, type ReactNode, useEffect, useState } from 'react'
import {
  type ActiveModifiers,
  type DateRange,
  DayPicker,
  type DayPickerMultipleProps,
  type DayPickerRangeProps,
  type DayPickerSingleProps,
} from 'react-day-picker'

import { FvButton, SliderPanel } from '@fv/client-components'

type ExtendedProps = {
  open?: boolean
  onClose?: () => void
  rightContent?: ReactNode
  showClear?: boolean
  onClear?: () => void
  onCancel?: () => void
  saveText?: string
  isSaving?: boolean
}

type SinglePanelProps = DayPickerSingleProps & {
  onSave?: (val: Date | undefined) => void
  titleContent?: (val: Date | undefined) => ReactNode
}
type MultiplePanelProps = DayPickerMultipleProps & {
  onSave?: (val: Date[]) => void
  titleContent?: (val: Date[]) => ReactNode
}
type RangePanelProps = DayPickerRangeProps & {
  onSave?: (val: DateRange | undefined) => void
  titleContent?: (val: DateRange | undefined) => ReactNode
}

type Props = (SinglePanelProps | MultiplePanelProps | RangePanelProps) &
  ExtendedProps

export const DayPickerPanel = ({
  open,
  onClose,
  rightContent,
  onSave,
  titleContent,
  showClear,
  onClear,
  onCancel,
  isSaving,
  saveText = 'Apply and close',
  ...dayPickerProps
}: Props) => {
  const showActions = !!onSave || showClear || !!onCancel
  const [localVal, setLocalVal] = useState<unknown>(
    dayPickerProps.selected as any,
  )
  const width =
    dayPickerProps.mode === 'range' || (dayPickerProps.numberOfMonths ?? 1) > 1
      ? '40.75rem'
      : !rightContent
        ? '22rem'
        : undefined

  const handleSelect = (
    nextVal: unknown,
    clickedDate: Date,
    modifiers: ActiveModifiers,
    e: MouseEvent,
  ) => {
    if (dayPickerProps.mode === 'range' && !(nextVal as DateRange).to) {
      const val = nextVal as DateRange
      nextVal = {
        ...val,
        to: val.from,
      }
    }
    setLocalVal(nextVal as any)
    if (!onSave) {
      dayPickerProps.onSelect(nextVal as any, clickedDate, modifiers, e)
    }
  }

  const handleSaveClick = () => {
    onSave?.(localVal as any)
  }

  useEffect(() => {
    if (!open) {
      setLocalVal(dayPickerProps.selected)
    }
  }, [open, setLocalVal, dayPickerProps.selected])

  return (
    <SliderPanel isOpen={open} closePanel={onClose} width={width}>
      {titleContent?.(localVal as any)}
      <div className="flex gap-8">
        <div>
          <DayPicker
            {...dayPickerProps}
            selected={localVal as any}
            onSelect={handleSelect}
          />
        </div>
        {!!rightContent && (
          <>
            <div>{rightContent}</div>
          </>
        )}
      </div>
      {showActions && (
        <div>
          <hr className="my-6" />
          <div className="flex items-center">
            {onSave && (
              <FvButton
                theme="primary"
                icon="check"
                onClick={handleSaveClick}
                loading={isSaving}
              >
                {saveText}
              </FvButton>
            )}
            {showClear && (
              <FvButton
                icon="times"
                theme="default"
                onClick={() => {
                  const val: any =
                    dayPickerProps.mode === 'multiple' ? [] : undefined
                  if (onSave) {
                    setLocalVal(val)
                  } else {
                    dayPickerProps.onSelect(val, null, null, null)
                  }
                  onClear?.()
                }}
              >
                Clear date
              </FvButton>
            )}
            {onCancel && (
              <FvButton theme="plain" icon="times" onClick={onCancel}>
                Cancel
              </FvButton>
            )}
          </div>
        </div>
      )}
    </SliderPanel>
  )
}
