import { setProperty } from 'dot-prop'
import { type FieldPath } from 'react-hook-form'
import toast from 'react-hot-toast'

import { downloadCSV } from '@fv/client-core'
import {
  type CSVRow,
  type Currency,
  type DistanceUOM,
  type EquipmentType,
} from '@fv/client-types'
import { type Country, CountryMapper, states } from '@fv/models'

import { type EquipmentTypeEnum } from '../../../types'
import { fuelTableTemplate } from './templates'
import {
  type FuelTableEntry,
  type ParsedFuelTableRow,
  type ParsedRate,
  type PartialContractedRate,
  type RateDetail,
} from './types'

const searchify = (s: string) => s.replace(/[^a-zA-Z0-9]+/g, '').toLowerCase()
const parsePrice = (s: string) => Number(s.replace(/\$/g, ''))

const parsePostalCode = (value: string) => {
  let postalCode = value

  if (value.length === 6) {
    // Ensure Canadian postal codes contain a space
    postalCode = `${value.slice(0, 3)} ${value.slice(3)}`
  }
  return postalCode
}

export function parseRate(data: CSVRow): ParsedRate {
  const rateEntries = Object.entries(data).map<
    [FieldPath<ParsedRate> | undefined, unknown | undefined]
  >(([k, v]) => {
    const key: string = k.replace(/ /g, '')
    const value = v as string

    if (/date/i.test(key)) {
      if (/end/i.test(key)) return ['endDate', value]
      if (/start/i.test(key)) return ['startDate', value]
    }

    if (/dest/i.test(key)) {
      if (/codeend/i.test(key)) {
        return ['destination.postalCodeEnd', parsePostalCode(value)]
      } else if (/code/i.test(key)) {
        return ['destination.postalCode', parsePostalCode(value)]
      } else if (/state/i.test(key) && value) {
        return [
          'destination.state',
          states.find(s => s.abbreviation === value || s.name === value)
            ?.abbreviation,
        ]
      } else if (/country/i.test(key)) {
        const country = CountryMapper.legacyToCore(value)
        return ['destination.country', country]
      }
    }

    if (/distance/i.test(key)) {
      if (/unit/i.test(key)) {
        let uom = value.toLowerCase().slice(0, 2)
        if (uom.charAt(0) === 'k') uom = 'km'
        return ['distanceUOM', uom]
      }
      return ['distance', Number(value)]
    }

    if (/equip/i.test(key)) {
      const equipmentTypes = value
        .split(',')
        .map(searchify)
        .filter(t => !!t)

      return ['equipmentTypes', equipmentTypes]
    }

    if (/fuel/i.test(key)) {
      let fuelIncluded = value.toLowerCase()

      try {
        fuelIncluded = JSON.parse(fuelIncluded)
      } catch (e) {
        console.warn(`Invalid value for "fuelIncluded": "${fuelIncluded}"`)
      }

      return ['fuelIncluded', fuelIncluded]
    }

    if (/number/i.test(key)) {
      return ['contractNumber', value]
    }

    if (/minimum/i.test(key)) {
      return ['minimumRate', parsePrice(value)]
    }

    if (/origin/i.test(key)) {
      if (/codeend/i.test(key)) {
        return ['origin.postalCodeEnd', parsePostalCode(value)]
      } else if (/code/i.test(key)) {
        return ['origin.postalCode', parsePostalCode(value)]
      } else if (/state/i.test(key) && value) {
        return [
          'origin.state',
          states.find(s => s.abbreviation === value || s.name === value)
            ?.abbreviation,
        ]
      } else if (/country/i.test(key)) {
        const country = CountryMapper.legacyToCore(value)
        return ['origin.country', country]
      }
    }

    if (/rate/i.test(key)) {
      if (/currency/i.test(key)) {
        return ['rate.currency', value.toLowerCase()]
      }
      if (/type/i.test(key)) {
        let rateType = value.replace(/-/g, '').toLocaleLowerCase()
        if (rateType === 'permile') rateType = 'per-mile'
        return ['rate.rateType', rateType]
      }
      return ['rate.amount', parsePrice(value)]
    }

    if (/service/i.test(key)) {
      return ['rate.serviceId', value.toLowerCase()]
    }
    return [undefined, undefined]
    // return [key, value]
  })

  const entry = {} as ParsedRate
  rateEntries
    .filter(([path]) => !!path)
    .forEach(([path, value]) => setProperty(entry, path as string, value))
  return entry
}

const defaultCountries: Country[] = ['us', 'ca']
const withMexico: Country[] = [...defaultCountries, 'mx']
const validateRateLocation = (
  loc: ParsedRate['origin'] | ParsedRate['destination'],
  name: string,
  row: string,
  allowMexico: boolean,
) => {
  const errors = []
  if (!loc) {
    return [`${name} must be specified`]
  }
  const countries = allowMexico ? withMexico : defaultCountries
  if (!countries.includes(loc.country as Country)) {
    errors.push(`${name} country must be ${countries.join(' or ')} in ${row}`)
  }
  if (!loc.postalCode && !loc.state) {
    errors.push(`${name} postal code or state is required in ${row}`)
  } else {
    if (
      loc.country === 'ca' &&
      ((loc.postalCode && loc.postalCode?.length !== 7) ||
        (loc.postalCodeEnd && loc.postalCodeEnd.length !== 7))
    ) {
      errors.push(`${name} postal code must be 6 characters in ${row}`)
    }

    if (
      loc.country === 'us' &&
      ((loc.postalCode && loc.postalCode.length !== 5) ||
        (loc.postalCodeEnd && loc.postalCodeEnd.length !== 5))
    ) {
      errors.push(`${name} postal code must be 5 digits in ${row}`)
    }
  }
  return errors
}

export function validateRates(
  rates: ParsedRate[],
  hasFuelAndDistance: boolean,
  hasMexico: boolean,
  carrierId?: string,
  equipmentOptions?: EquipmentTypeEnum[],
) {
  if (!carrierId) return { errors: ['carrier is required'], validRates: [] }
  if (!equipmentOptions) {
    return { errors: ['unable to validate equipment type(s)'], validRates: [] }
  }

  const errors: string[] = []
  const validRates: PartialContractedRate[] = []

  rates.forEach((r, index) => {
    // Accounting for header row
    const row = `row ${index + 2}`
    const rateErrors: string[] = []

    const {
      contractNumber,
      destination,
      fuelIncluded,
      origin,
      rate,
      endDate,
      startDate,
      minimumRate,
    } = r

    let { amount, currency, serviceId } = rate ?? {}

    let { distance, distanceUOM, equipmentTypes } = r

    // Handle missing data and empty cells coming in as empty strings
    amount = amount || 0
    currency = currency || 'usd'
    distance = distance || 0
    distanceUOM = distanceUOM || 'mi'
    equipmentTypes = equipmentTypes || []
    serviceId = serviceId || 'truckload'
    const endDateDate = endDate ? new Date(endDate) : undefined
    const startDateDate = startDate ? new Date(startDate) : undefined

    const validEquipmentTypes = equipmentTypes
      .map(t => {
        const type = equipmentOptions?.find(e => {
          return t === searchify(e.name) || t === searchify(e.key)
        })

        return type?.key
      })
      .filter((t): t is EquipmentType => !!t)

    if (isNaN(amount) || amount <= 0) {
      rateErrors.push(`rate must be greater than 0 in ${row}`)
    }

    if (currency !== 'usd') {
      rateErrors.push(`rate currency must be 'usd' in ${row}`)
    }

    rateErrors.push(
      ...validateRateLocation(destination, 'destination', row, hasMexico),
    )
    rateErrors.push(...validateRateLocation(origin, 'origin', row, hasMexico))

    if (distance) {
      if (isNaN(distance)) {
        rateErrors.push(`distance must be a number in ${row}`)
      } else if (distance < 0) {
        rateErrors.push(`distance must be greater than 0 in ${row}`)
      }
    }

    if (distanceUOM !== 'mi') {
      rateErrors.push(`distance unit must be 'mi' in ${row}`)
    }

    if (!endDate || isNaN(new Date(endDate).valueOf())) {
      rateErrors.push(`invalid or missing contract end date in ${row}`)
    }

    if (!startDate || isNaN(new Date(startDate).valueOf())) {
      rateErrors.push(`invalid or missing contract start date in ${row}`)
    }

    if (rate?.rateType !== 'flat') {
      if (!hasFuelAndDistance) {
        rateErrors.push(`rate type must be 'flat' in ${row}`)
      } else if (rate?.rateType !== 'per-mile') {
        rateErrors.push(`rate type must be 'flat' or 'per-mile' in ${row}`)
      }
    }

    if (serviceId !== 'truckload') {
      rateErrors.push(`service ID must be 'truckload' in ${row}`)
    }
    if (typeof fuelIncluded !== 'boolean') {
      rateErrors.push(`fuel included must be 'true' or 'false' in ${row}`)
    }

    if (!validEquipmentTypes.length) {
      rateErrors.push(`invalid equipment type in ${row}`)
    }

    if (Number.isNaN(minimumRate)) {
      rateErrors.push(`invalid minimum rate in ${row}`)
    }

    if (rateErrors.length) {
      errors.push(...rateErrors)
    } else {
      validRates.push({
        carrierId,
        destination: {
          country: destination?.country as Country,
          postalCode: destination?.postalCode as string,
          postalCodeEnd: destination?.postalCodeEnd as string,
          state: destination?.state as string,
        },
        distanceUOM: distanceUOM as DistanceUOM,
        endDate: endDateDate?.valueOf() ? endDateDate.toISOString() : '',
        equipmentTypes: validEquipmentTypes,
        fuelIncluded: fuelIncluded as boolean,
        origin: {
          country: origin?.country as Country,
          postalCode: origin?.postalCode as string,
          postalCodeEnd: origin?.postalCodeEnd as string,
          state: origin?.state as string,
        },
        rate: {
          amount,
          currency: currency as Currency,
          rateType: rate?.rateType as RateDetail['rateType'],
          serviceId: 'truckload',
        },
        minimumRate,
        startDate: startDateDate?.valueOf() ? startDateDate.toISOString() : '',
        ...(contractNumber && { contractNumber }),
        ...(distance && { distance }),
      })
    }
  })

  return { errors, validRates }
}

export function csvToFuelTableRow(data: CSVRow): ParsedFuelTableRow {
  const row: ParsedFuelTableRow = {}

  Object.entries(data).forEach(([k, v]) => {
    const key = k.replace(/\W/g, '')
    const value = v

    if (/lower|min|bottom|from/i.test(key)) {
      row.lowerBoundary = Number(value)
    } else if (/upper|max|top|to/i.test(key)) {
      row.upperBoundary = Number(value)
    } else if (/type/i.test(key)) {
      let type = value.replace(/\W/g, '')
      if (/permile/i.test(type)) type = 'per-mile'
      row.calculationType = type
    } else if (/currency/i.test(key)) {
      row.currency = value.toLowerCase()
    } else if (/amount|rate|value/i.test(key)) {
      row.rate = Number(value)
    }
  })

  return row
}

export function validateFuelTableRows(parsedRows: ParsedFuelTableRow[]) {
  const errors: string[] = []
  const rows: FuelTableEntry[] = []

  parsedRows.forEach((r, index) => {
    // Accounting for header row
    const row = `row ${index + 2}`
    const rowErrors: string[] = []

    // Defaults for empty or missing columns
    const calculationType = r.calculationType || 'per-mile'
    const currency = r.currency || 'usd'
    const lowerBoundary = r.lowerBoundary ?? -1
    const upperBoundary = r.upperBoundary ?? -1
    const rate = r.rate ?? -1

    if (
      typeof lowerBoundary !== 'number' ||
      isNaN(lowerBoundary) ||
      lowerBoundary < 0
    ) {
      rowErrors.push(`invalid lower boundary in ${row}`)
    }

    if (
      typeof upperBoundary !== 'number' ||
      isNaN(upperBoundary) ||
      upperBoundary < 0
    ) {
      rowErrors.push(`invalid upper boundary in ${row}`)
    }

    if (
      typeof lowerBoundary === 'number' &&
      typeof upperBoundary === 'number' &&
      !isNaN(lowerBoundary) &&
      !isNaN(upperBoundary) &&
      lowerBoundary > upperBoundary
    ) {
      rowErrors.push(
        `lower boundary must be less than upper boundary in ${row}`,
      )
    }

    if (calculationType !== 'per-mile') {
      rowErrors.push(`calculation type must be 'per mile' in ${row}`)
    }

    if (typeof rate !== 'number' || isNaN(rate) || rate < 0) {
      rowErrors.push(`invalid rate in ${row}`)
    }

    if (currency !== 'usd') {
      rowErrors.push(`currency must be 'usd' in ${row}`)
    }

    if (rowErrors.length) {
      errors.push(...rowErrors)
    } else {
      rows.push({
        calculationType: 'per-mile',
        currency: 'usd',
        lowerBoundary,
        rate,
        upperBoundary,
      })
    }
  })

  return { errors, rows }
}

export function exportFuelTable() {
  const csvData: string[] = []
  const rows = document.getElementsByClassName('fuel-table-row')

  if (!rows?.length) {
    toast.error('No fuel table found.')
    return
  }

  const headers = fuelTableTemplate[0]
  csvData.push(headers)

  Array.from(rows).forEach(r => {
    const csvRow: string[] = []

    Array.from(r.children).forEach(c => {
      let value = c.textContent ?? ''
      value = value.replace('$', '').toLocaleLowerCase()
      csvRow.push(value)
    })

    csvData.push(csvRow.join(','))
  })

  downloadCSV(csvData, 'FuelTable')
}
