import { FvLinkButton } from '@fv/client-components'
import { marketingUri, registerCredentialDetailHelper } from '@fv/client-core'

import InfoBox from '../../../components/InfoBox'
import { InputGroup } from '../../../components/inputs/InputGroup'
import { type CredentialFormProps } from '../types'

export const UpsProvisioning = (props: CredentialFormProps) => {
  const { carrier, value, onChange } = props
  const integrationGuideUrl = `${marketingUri}/ups-integration`

  return (
    <>
      <InfoBox>
        In order to add UPS&#8482;, you must follow the guide at{' '}
        <FvLinkButton
          theme="callout"
          href={integrationGuideUrl}
          target="_blank"
        >
          {integrationGuideUrl}
        </FvLinkButton>{' '}
        to link your UPS&#8482; account with Freightview.
      </InfoBox>

      <p className="text-sm -mt-2 italic p-2 bg-red-100 border-red-200 border">
        <span className="font-bold">NOTE:</span> You must complete all steps in{' '}
        <FvLinkButton
          theme="underlined"
          href={integrationGuideUrl}
          target="_blank"
        >
          this guide
        </FvLinkButton>
        , including adding the correct UPS&#8482; products, in order for the
        connection to work properly.
      </p>
      <div className="grid gap-x-4 lg:grid-cols-2">
        <InputGroup
          label="Client ID"
          inputType="text"
          className="basis-full lg:basis-1/3"
          required
          inputProps={{
            placeholder: `Client ID for ${carrier.name}`,
            type: 'text',
            ...registerCredentialDetailHelper('client-id', value, onChange),
            name: `${carrier.code}-client-id`,
          }}
        />
        <InputGroup
          label="Client secret"
          inputType="text"
          className="basis-full lg:basis-1/3"
          required
          inputProps={{
            placeholder: `Client secret for ${carrier.name}`,
            type: 'password',
            ...registerCredentialDetailHelper('client-secret', value, onChange),
            name: `${carrier.code}-client-secret`,
          }}
        />
        <InputGroup
          label="Account number"
          inputType="text"
          className="basis-full lg:basis-1/3"
          required
          inputProps={{
            placeholder: `Account number for ${carrier.name}`,
            type: 'text',
            ...registerCredentialDetailHelper(
              'account-number',
              value,
              onChange,
            ),
            name: `${carrier.code}-account-number`,
          }}
        />
      </div>
    </>
  )
}
