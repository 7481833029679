import { isHolidayOrWeekend } from '@freightview/holidays'
import dayjs from 'dayjs'
import maxBy from 'lodash/maxBy'
import orderBy from 'lodash/orderBy'

import { isFedex } from '@fv/client-core'
import {
  type FullShipment,
  type Quote,
  type UIQuote,
  type Workflow,
} from '@fv/client-types'

import { reverseLocations } from '../../components/ShipmentDetails/useCreateReturnShipment'
import { locationToFormStop } from '../../utils/locationFuncs'
import { equipmentToFormLoad } from '../quote-request/quoteRequestUtils'
import { type QuoteRequestLoads } from '../quote-request/types'
import { type QuotePageLoadDetails } from './types'

export type ImportantQuoteProps = Pick<
  Quote,
  | '_id'
  | 'pricingType'
  | 'serviceId'
  | 'method'
  | 'isLegacy'
  | 'pricingMethod'
  | 'carrierId'
  | 'mode'
  | 'status'
  | 'isSpot'
>

export const quoteFuncs = {
  isSpotWorkflow(quote?: ImportantQuoteProps) {
    if (!quote) return false

    return !!quote.isSpot
  },

  getRecentAPIQuoteDate(
    { createdDate }: Pick<FullShipment, 'createdDate'>,
    quotes?: UIQuote[],
  ) {
    const apiQuotes = quotes?.filter(r => r.method === 'api') ?? []
    const quoteDate = maxBy(apiQuotes, r => r.createdDate)?.createdDate

    return quoteDate ?? createdDate
  },

  isFedex(quote?: UIQuote) {
    return isFedex(quote?.providerCode ?? quote?.assetCarrierCode)
  },
  applyMarkup<T extends UIQuote | Quote>(
    quote: T,
    workflow: Workflow,
    rateModifier?: number,
  ): T {
    if (
      workflow !== 'ltl' ||
      quote.source === 'shipper' ||
      quoteFuncs.isSpotWorkflow(quote) ||
      quote.hasMarkup ||
      !rateModifier
    ) {
      return quote
    }

    const multiplier = rateModifier / 100 + 1
    const amount = Math.round(100 * (quote.amount * multiplier)) / 100
    return {
      ...quote,
      amount,
      charges: [
        ...(quote.charges ?? []),
        {
          amount: amount - quote.amount,
          currency: 'usd',
          name: `${rateModifier.toFixed(2)}% user markup`,
        },
      ],
    }
  },

  filterActiveDirect(quotes?: UIQuote[]) {
    return (
      quotes?.filter(
        q =>
          !quoteFuncs.isSpotWorkflow(q) &&
          (q.status === 'active' || q.status === 'awarded'),
      ) ?? []
    )
  },

  cheapestQuote(quotes?: UIQuote[]) {
    return orderBy(quotes ?? [], q => q.amount)[0]
  },

  getEstimatedDeliveryDate(
    pickupDate?: string,
    {
      transitDaysMin,
      deliveryDate,
    }: Pick<UIQuote, 'transitDaysMin' | 'deliveryDate'> = {},
  ) {
    if (deliveryDate) {
      return dayjs(deliveryDate)
    }

    if (!transitDaysMin || !pickupDate) {
      return
    }

    let incrementDate = 1

    while (incrementDate <= transitDaysMin) {
      const day = dayjs(pickupDate).add(incrementDate, 'days')
      if (isHolidayOrWeekend(day.toDate())) {
        transitDaysMin += 1
      }
      incrementDate += 1
    }

    return dayjs(pickupDate).add(transitDaysMin, 'days')
  },
}

function getQuoteRequestIdentifier(loads?: FullShipment[]) {
  if (!loads?.length) return ''

  const refNums = new Set<string>()

  loads.forEach(s => {
    s.refNums?.forEach(n => {
      refNums.add(n.value)
    })
  })

  if (refNums.size > 0) return Array.from(refNums).join(', ')

  const stops = loads[0].locations
  const origin = stops[0]
  const dest = stops[stops.length - 1]

  return `${origin.city}, ${origin.state} to ${dest.city}, ${dest.state}`
}

export type QuoteRequestMode = 'copy' | 'edit' | 'return'

export const quoteRequestMapper = {
  ltlRequest: (props: {
    load: FullShipment
    mode: QuoteRequestMode
  }): QuotePageLoadDetails => {
    const { load, mode } = props
    const pickUpDate = dayjs(load.locations[0].stopDate)
    const isAfterPickUp = dayjs().isAfter(pickUpDate, 'day')
    const trimRefNums = mode === 'copy'
    let locations = load.locations
    if (mode === 'return') {
      locations = reverseLocations(locations)
    }
    return {
      load: {
        isFreightCollect: !!load.isFreightCollect,
        loadId: load.loadId,
        workflow: load.workflow,
        protectFromFreezing: !!load.equipment.accessorials?.some(
          a => a.key === 'protect-from-freezing',
        ),
        quoteRequestId: load.quoteRequestId,
        rateLocationType:
          load.direction === 'inbound' ? 'destination' : 'origin',
        schedulePickup: load.schedulePickup,
      },
      locations: locations.map(x =>
        locationToFormStop(
          {
            ...x,
            ...(isAfterPickUp &&
              mode === 'copy' && {
                stopDate: x.sequence === 0 ? dayjs().toISOString() : null,
              }),
            ...(trimRefNums && {
              refNums: [],
            }),
          },
          load.workflow,
        ),
      ),
    }
  },
  tlRequest: (templates: FullShipment[], mode: QuoteRequestMode) => {
    const firstLoad = templates[0]
    const loads: QuoteRequestLoads = new Map()
    const trimRefNums = mode === 'copy'
    const orderNum = firstLoad.refNums?.[0]?.value ?? ''
    const metadata = {
      isLiveLoad: firstLoad.isLiveLoad,
      orderNumber: trimRefNums ? '' : orderNum,
      quoteRequestId: firstLoad.quoteRequestId,
      requestIdentifier: getQuoteRequestIdentifier(templates),
    }

    templates.forEach(({ equipment, loadId, items }) => {
      const nextLoadsOfType = loads.get(equipment.type) ?? []
      const nextLoad = equipmentToFormLoad({
        equipment,
        loadId,
        items,
        billTo: firstLoad.billTo,
        customsBroker: firstLoad.customsBroker,
        emergencyContact: firstLoad.emergencyContact,
      })

      loads.set(equipment.type, nextLoadsOfType.concat(nextLoad))
    })

    // Activate first of each equipment type
    for (const type of loads.keys()) {
      const firstLoadOfType = loads.get(type)?.[0]
      if (firstLoadOfType) firstLoadOfType.isActiveInUI = true
    }

    const pickUpDate = dayjs(firstLoad.locations[0].stopDate)
    const isAfterPickUp = dayjs().isAfter(pickUpDate, 'day')
    const stops = firstLoad.locations.map(x =>
      locationToFormStop(
        {
          ...x,
          ...(isAfterPickUp &&
            mode === 'copy' && {
              stopDate: x.sequence === 0 ? dayjs().toISOString() : null,
            }),
          ...(trimRefNums && {
            refNums: [],
          }),
        },
        'truckload',
      ),
    )

    return { loads, metadata, stops }
  },
}
