import {
  FvButton,
  FvLinkButton,
  Icon,
  ValidatedForm,
} from '@fv/client-components'
import {
  createCredentailDetails,
  freightviewUri,
  showApiError,
  useFormModelState,
} from '@fv/client-core'
import {
  carrierModeTypes,
  type CarrierProvisioningDetails,
} from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import CheckboxField from '../../components/inputs/CheckboxField'
import { InputGroupWrapper } from '../../components/inputs/InputGroup'
import { Loading } from '../../components/Loading'
import { AccountCarrierCredentialForm } from './AccountCarrierCredentialForm'
import {
  carrierCredFormProvider,
  provisionComponentProvider,
} from './CarrierComponentProvider'
import { useUpsertCarrier } from './mutations'
import { useAccountCarrier, useCarrierProvisioningDetails } from './queries'
import { SalesRepForm } from './SalesRepForm'
import {
  type AccountCarrierFormModel,
  type BaseProvisionFormProps,
} from './types'
import {
  fillModeDefaults,
  getCarrierModeDisplay,
  isBetaEnabled,
  toFormModel,
} from './utils'

type AccountCarrierFormProps = BaseProvisionFormProps & {
  carrierId: string
  locationId?: string
  editing?: boolean
}
export const AccountCarrierForm = (props: AccountCarrierFormProps) => {
  const { carrierId, onCancel, onSuccess, editing, locationId } = props
  const { data } = useCarrierProvisioningDetails(carrierId)
  const { data: existingAccountCarrier } = useAccountCarrier(carrierId)

  if (!data) {
    return <Loading />
  }

  const formModel = fillModeDefaults(toFormModel(existingAccountCarrier), data)
  const beta = isBetaEnabled(data, existingAccountCarrier)
  const CarrierComponent = provisionComponentProvider[data.code ?? '']?.({
    beta,
  })
  if ((!editing || locationId) && CarrierComponent) {
    return <CarrierComponent onCancel={onCancel} onSuccess={onSuccess} />
  }

  const hasCredForm = !!carrierCredFormProvider[data.code ?? '']?.({ beta })

  return (
    <div>
      <h5 className="mb-4 border-b pb-2">
        <Icon icon="truck-fast" className="color-dark" />
        <span>{data.name}</span>
      </h5>

      {!hasCredForm && (
        <CarrierNotice carrier={data} exists={!!existingAccountCarrier} />
      )}

      <Form
        model={formModel}
        beta={beta}
        carrierDetails={data}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />
    </div>
  )
}

type FormProps = BaseProvisionFormProps & {
  model: AccountCarrierFormModel
  carrierDetails: CarrierProvisioningDetails
  beta?: boolean
}
const Form = ({
  model: initialModel,
  carrierDetails,
  onSuccess,
  onCancel,
  beta,
}: FormProps) => {
  const upsertCarrier = useUpsertCarrier({ isOnboarding: false })

  const { register, value, setValue } = useFormModelState({
    initialValue: {
      ...initialModel,
      details: createCredentailDetails(
        carrierDetails.credentialTypes,
        initialModel?.details ?? [],
      ),
    },
  })
  const carrierModes = carrierModeTypes
    .map(mode => ({ mode, ...carrierDetails[mode] }))
    .filter(c => c.enabled)

  const handleSubmit = async () => {
    try {
      await upsertCarrier.mutateAsync({
        ...value,
        carrierId: carrierDetails.carrierId,
      })
      onSuccess?.()
    } catch (e) {
      showApiError('', e)
    }
  }
  return (
    <ValidatedForm className="pt-2" onValidSubmit={handleSubmit}>
      <AccountCarrierCredentialForm
        beta={beta}
        value={value.details}
        onChange={details => setValue(v => ({ ...v, details }))}
        carrier={carrierDetails}
      />
      <SalesRepForm register={register} carrier={carrierDetails} />
      {carrierModes.length > 1 && (
        <InputGroupWrapper
          label="Use the following services with Freightview"
          name="modes"
          className="mt-6"
        >
          <div className=" flex w-full gap-x-6 border-b border-t border-dotted border-[#ccc] py-3">
            {carrierModes.map(m => (
              <CheckboxField
                name={m.mode}
                key={m.mode}
                label={getCarrierModeDisplay(m.carrierNameOverride, m.mode)}
                checked={value.modes.find(x => x.modeType === m.mode)?.enabled}
                onChange={e =>
                  setValue(prev => ({
                    ...prev,
                    modes: prev.modes.map(pm =>
                      pm.modeType === m.mode
                        ? {
                            ...pm,
                            enabled: e.target.checked,
                          }
                        : pm,
                    ),
                  }))
                }
              />
            ))}
          </div>
        </InputGroupWrapper>
      )}
      <div className="flex pt-4">
        <FvButton
          theme="primary"
          icon="check"
          type="submit"
          loading={upsertCarrier.isLoading}
        >
          {carrierDetails.status === 'pending'
            ? 'Add to my list'
            : initialModel
              ? 'Update'
              : 'Authorize'}
        </FvButton>
        <FvButton theme="plain" onClick={onCancel} icon="times">
          Cancel
        </FvButton>
      </div>
    </ValidatedForm>
  )
}

const CarrierNotice = ({
  carrier,
  exists,
}: {
  carrier: CarrierProvisioningDetails
  exists?: boolean
}) => {
  if (carrier.status === 'pending') {
    return (
      <InfoBox>
        {carrier.name} doesn't currently participate in Freightview. We'd love
        to have them join us. Go ahead and put them on your list of carriers and
        brokers so we can record your vote and notify you if they become
        available.
      </InfoBox>
    )
  }

  return (
    <InfoBox>
      For {carrier.name}, we just need your permission to connect, then we'll
      handle the rest. By clicking below, you authorize us to work with{' '}
      {carrier.name} to retrieve your rates. (This is covered in more detail in
      the{' '}
      <FvLinkButton
        href={`${freightviewUri}/terms`}
        target="_blank"
        theme="underlined"
        rel="noreferrer"
      >
        Terms & Conditions
      </FvLinkButton>
      .)
    </InfoBox>
  )
}
