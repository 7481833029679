import { registerCredentialDetailHelper } from '@fv/client-core'

import { InputGroup } from '../../../components/inputs/InputGroup'
import { type CredentialFormProps } from '../types'

export const UsernamePasswordForm = ({
  carrier,
  value,
  onChange,
}: CredentialFormProps) => {
  if (
    !carrier.credentialTypes?.some(t => t.name === 'username') &&
    !carrier.credentialTypes?.some(t => t.name === 'password')
  )
    return null

  const hasPW = value.some(v => v.type === 'password')

  return (
    <div className="grid gap-x-4 lg:grid-cols-2">
      <InputGroup
        label="Username"
        inputType="text"
        className="basis-full lg:basis-1/3"
        required
        inputProps={{
          placeholder: `Username for ${carrier.name}`,
          type: 'text',
          ...registerCredentialDetailHelper('username', value, onChange),
          name: `${carrier.code}-username`,
        }}
      />
      {hasPW && (
        <InputGroup
          label="Password"
          inputType="text"
          className="basis-full lg:basis-1/3"
          required
          inputProps={{
            placeholder: `Password for ${carrier.name}`,
            type: 'password',
            ...registerCredentialDetailHelper('password', value, onChange),
            name: `${carrier.code}-password`,
          }}
        />
      )}
    </div>
  )
}
