import '../../components/inputs/ReactSelectClasses.scss'

import clsx from 'clsx'
import toast from 'react-hot-toast'
import { type ActionMeta, type OnChangeValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import InfoBox from '../../components/InfoBox'
import { useLoad } from '../../hooks/shipments'
import reactSelectStyles from '../../styles/reactSelectStyles'
import { removeUndefinedValues } from '../../utils/removeUndefinedValues'
import {
  useAccountTagHelpers,
  useShipmentTagMutations,
} from './useShipmentTagMutations'
import { type ShipmentTagOption, useTagSettings } from './useShipmentTags'

type ShipmentTagsControlProps = {
  loadId: string
}

export const ShipmentTagsControl = ({ loadId }: ShipmentTagsControlProps) => {
  const { addTag, isAdding, isRemoving, removeTag } =
    useShipmentTagMutations(loadId)
  const loadQuery = useLoad(loadId)

  const handleChange = (
    v: OnChangeValue<ShipmentTagOption, true>,
    meta: ActionMeta<ShipmentTagOption>,
  ) => {
    const { action, option, removedValue } = meta
    if (
      (action === 'create-option' || action === 'select-option') &&
      !!option
    ) {
      return addTag(option)
    }

    if (action === 'remove-value') {
      return removeTag(removedValue)
    }
    return
  }

  return loadQuery.isLoading && loadQuery.isFetching ? (
    <div>
      <InfoBox icon="spinner">Loading...</InfoBox>
    </div>
  ) : (
    <TagSelector
      isLoading={isAdding || isRemoving}
      tagNames={loadQuery.data?.tags?.map(t => t.name) ?? []}
      onChange={handleChange}
    />
  )
}

type OnChange = (
  v: OnChangeValue<ShipmentTagOption, true>,
  meta: ActionMeta<ShipmentTagOption>,
) => void
type TagSelectorProps = {
  onChange: OnChange
  isLoading: boolean
  tagIds?: string[]
  tagNames?: string[]
}

export const TagSelector = ({
  onChange,
  isLoading,
  tagIds,
  tagNames,
}: TagSelectorProps) => {
  const {
    canCreate,
    canModify,
    isLoading: tagsLoading,
    options,
  } = useTagSettings()
  const { modifyAccountTags } = useAccountTagHelpers()
  const handleChange = (
    v: OnChangeValue<ShipmentTagOption, true>,
    meta: ActionMeta<ShipmentTagOption>,
  ) => {
    if (!canModify) {
      return toast.error(
        'You do not have the required permissions to modify tags.',
      )
    }

    const { action, option } = meta

    if (action === 'create-option' && !canCreate) {
      return toast.error(
        'You do not have the required permissions to create tags.',
      )
    }

    if (action === 'create-option') {
      modifyAccountTags(
        tags =>
          tags
            ?.filter(t => t._id !== option.value)
            .concat({
              _id: option.value ?? option.label,
              label: option.label,
            }) || [],
      )
    }

    onChange(v, meta)
    return
  }

  const value = tagNames?.length
    ? tagNames.map(n => options.find(o => o.label === n))
    : tagIds?.map(id => options.find(o => o.value === id)) ?? []

  return (
    <CreatableSelect
      className={clsx('ReactSelectOverlay multi')}
      classNamePrefix="react-select"
      isClearable={false}
      isLoading={isLoading || tagsLoading}
      isDisabled={!canModify}
      isMulti
      isValidNewOption={input => !!input?.trim() && !!canCreate}
      onChange={handleChange}
      options={options}
      styles={reactSelectStyles}
      value={removeUndefinedValues(value)}
    />
  )
}
