import {
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
  useCallback,
  useMemo,
} from 'react'

import CheckboxField from '../inputs/CheckboxField'
import { MultiSelectFilter } from './MultiSelectFilter'
import { SelectFilter } from './SelectFilter'
import { type FilterConfig } from './types'

type FilterControlProps = FilterConfig & {
  queryString: string
  setQueryString: Dispatch<SetStateAction<string>>
}

const FilterControl = ({
  FilterComponent,
  label,
  name,
  options,
  queryString,
  setQueryString,
}: FilterControlProps) => {
  const checkboxName = `${name}_checkbox`
  const firstOption = options[0]
  const isMulti = name.slice(-2) === '[]'
  const params = new URLSearchParams(queryString)
  const value = isMulti ? params.getAll(name) : params.get(name) ?? ''
  const isSelected = isMulti ? value?.length > 0 : Boolean(value)

  const LocalFilterComponent = useMemo(() => {
    if (FilterComponent) return FilterComponent
    return isMulti ? MultiSelectFilter : SelectFilter
  }, [FilterComponent, isMulti])

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setQueryString(qs => {
        const checked = e.target.checked
        const params = new URLSearchParams(qs)

        if (checked) {
          params.append(name, firstOption?.value)
        } else {
          params.delete(name)
        }

        return params.toString()
      })
    },
    [firstOption, name, setQueryString],
  )

  return (
    <div className="filter-type">
      <div className="flex items-center">
        <label htmlFor={checkboxName}>{label}</label>
        <div className="flex-pinline" />
        <CheckboxField
          checked={isSelected}
          name={checkboxName}
          onChange={onChange}
        />
      </div>

      {isSelected && (
        <LocalFilterComponent
          name={name}
          options={options}
          setQueryString={setQueryString}
          value={value as any}
        />
      )}
    </div>
  )
}

export default FilterControl
