import { type PropsWithChildren } from 'react'

import { FvLinkButton, type FvLinkButtonProps } from '@fv/client-components'

import { useDownloadRatesPDF } from './useDownloadRatesPDF'

type Props = FvLinkButtonProps & {
  loadId: string
}

export const PrintRatesButton = ({
  loadId,
  ...rest
}: PropsWithChildren<Props>) => {
  const { setFetching, isFetchingFile } = useDownloadRatesPDF(loadId)
  return (
    <FvLinkButton
      onClick={() => setFetching(true)}
      icon={isFetchingFile ? 'spinner' : 'print'}
      {...rest}
    >
      {rest.children}
    </FvLinkButton>
  )
}
