import pluralize from 'pluralize'
import { useCallback, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams, useSearchParams } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import {
  FvButton,
  FvLinkButton,
  useFvNavigate,
  VirtualList,
} from '@fv/client-components'
import { toggleArrayItem } from '@fv/client-core'

import InfoBox from '../../../components/InfoBox'
import { Loading } from '../../../components/Loading'
import { HasFeature } from '../../auth'
import { ContractedRateRow } from './ContractedRateRow'
import { ExtendContractedRates } from './ExtendContractedRates'
import FuelTablePanel from './FuelTablePanel'
import { useFilteredRates } from './hooks'
import {
  type ContractedRatesFilter,
  ContractedRatesListFilter,
} from './ListFilter'
import { useRemoveContractedRates } from './mutations'
import { useContractedRateCarriers, useContractedRates } from './queries'
import { type ContractedRate } from './types'

export const ContractedRatesList = () => {
  const { rateId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<ContractedRatesFilter>({
    carrierId: searchParams.get('carrierId') ?? '',
    locSearch: '',
    contractSearch: '',
  })
  const [debouncedFilter] = useDebounce(filter, 500)
  const removeRates = useRemoveContractedRates(filter.carrierId)
  const ratesQuery = useContractedRates(filter.carrierId)
  const carriersQuery = useContractedRateCarriers()
  const [checked, setChecked] = useState<string[]>([])
  const carrier = carriersQuery.data?.find(
    c => c.carrierId === filter.carrierId,
  )
  const filteredRates = useFilteredRates(debouncedFilter, ratesQuery.data ?? [])
  const [isEditingFuel, setEditingFuel] = useState(false)
  const scrollRef = useRef<HTMLDivElement>(null)
  const hasErrors = filteredRates.some(r => !!r.errors?.length)
  const hasCarriers = (carriersQuery.data?.length ?? 0) > 0
  const [isFixingErrors, setFixingErrors] = useState(false)
  const rates = sortRates(filteredRates, isFixingErrors)
  const navigate = useFvNavigate()
  const navigateToList = () => {
    return navigate('/settings/contracted-rates')
  }

  useEffect(() => {
    if (
      !filter.carrierId &&
      carriersQuery.data &&
      carriersQuery.data.length > 0
    ) {
      setFilter(p => ({ ...p, carrierId: carriersQuery.data[0].carrierId }))
    }
  }, [carriersQuery, filter])

  useEffect(() => {
    setSearchParams({ carrierId: filter.carrierId })
  }, [filter, setSearchParams])

  const handleRateChecked = useCallback(
    (rateId: string, isChecked: boolean) => {
      setChecked(toggleArrayItem(checked, rateId, isChecked))
    },
    [checked],
  )

  if (carriersQuery.isLoading || (!filter.carrierId && hasCarriers)) {
    return <Loading />
  }
  return (
    <div className="-mb-10">
      <ContractedRatesListFilter value={filter} onChange={setFilter} />
      <div className=" border-b-fv-orange flex divide-x divide-gray-300 border-b py-3">
        {!isEditingFuel && (
          <FvLinkButton
            className="px-3"
            href="#"
            onClick={() => {
              setChecked(
                checked.length === filteredRates.length
                  ? []
                  : filteredRates.map(d => d._id) ?? [],
              )
            }}
            icon="check-double"
          />
        )}{' '}
        {carrier?.carrierName && (
          <span className="px-3">{carrier.carrierName}</span>
        )}
        {!isEditingFuel && (
          <div className="px-3">
            <ExtendContractedRates
              carrierId={debouncedFilter.carrierId}
              postalCode={debouncedFilter.locSearch}
              contractId={debouncedFilter.contractSearch}
              rateCount={rates.length}
            />
          </div>
        )}
        <HasFeature name="fuelAndDistance">
          <FvButton
            className="px-3"
            onClick={() => setEditingFuel(!isEditingFuel)}
            icon={isEditingFuel ? 'list' : 'eye'}
          >
            {isEditingFuel ? 'View rates' : 'Add / view fuel'}
          </FvButton>
        </HasFeature>
      </div>
      <div
        className="h-full overflow-auto pb-16"
        style={{ maxHeight: 'calc(100vh - 17rem)' }}
        ref={scrollRef}
      >
        {isEditingFuel && (
          <FuelTablePanel isOpen={true} carrierId={filter.carrierId} />
        )}
        {!isEditingFuel && (
          <>
            {hasErrors && (
              <InfoBox className="mt-6" icon="exclamation-triangle">
                You have rates with invalid postal codes.{' '}
                {!isFixingErrors && (
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setFixingErrors(true)
                    }}
                    style={{ color: '#45abd3' }}
                  >
                    Move them to the top of each list below.
                  </a>
                )}
              </InfoBox>
            )}
            {filteredRates.length ? (
              <VirtualList
                items={rates}
                estimateSize={index => (rates[index]._id === rateId ? 750 : 75)}
                scrollRef={scrollRef}
                renderItem={rate => (
                  <ContractedRateRow
                    key={rate._id}
                    rate={rate}
                    flash={isFixingErrors}
                    checked={checked.includes(rate._id)}
                    onChecked={handleRateChecked}
                    isEditing={rateId === rate._id}
                    onSubmit={navigateToList}
                    onCancel={navigateToList}
                  />
                )}
              />
            ) : (
              <div className="py-6">
                {hasCarriers && ratesQuery.isLoading ? (
                  <Loading />
                ) : (
                  <InfoBox>
                    No contracted rates match the selected filters.
                  </InfoBox>
                )}
              </div>
            )}
          </>
        )}
      </div>

      {checked.length > 0 && (
        <div className="border-top bg-fv-blue-100 border-t-fv-blue-300 fixed bottom-0 left-0 flex w-full flex-wrap items-center justify-center border-t py-4">
          <FvButton theme="plain" icon="times" onClick={() => setChecked([])}>
            <span>Cancel</span>
          </FvButton>
          <FvButton
            loading={removeRates.isLoading}
            icon="trash"
            iconClass="text-fv-orange"
            theme="default"
            onClick={() => {
              if (removeRates.isLoading) return

              const count = checked.length
              const isConfirmed = window.confirm(
                `You are about to remove ${count} contracted ${pluralize(
                  'rate',
                  count,
                )}. Are you sure?`,
              )

              if (!isConfirmed) return

              removeRates
                .mutateAsync(checked)
                .catch(e => {
                  toast.error(e.message)
                })
                .finally(() => {
                  setChecked([])
                  removeRates.reset()
                })
            }}
          >
            <span>Delete selected rates</span>
          </FvButton>
        </div>
      )}
    </div>
  )
}

const sortRates = (rates: ContractedRate[], errorsOnTop = false) => {
  return rates.sort((a, b) => {
    if (errorsOnTop) {
      if (a.errors?.length) return -1
      if (b.errors?.length) return 1
    }

    return (
      (a.origin.postalCode?.localeCompare(b.origin.postalCode ?? '') ?? 0) ||
      (a.destination.postalCode?.localeCompare(
        b.destination.postalCode ?? '',
      ) ??
        0)
    )
  })
}
