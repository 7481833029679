import orderBy from 'lodash/orderBy'
import { useState } from 'react'

import { FvButton, FvLinkButton } from '@fv/client-components'

import InfoBox from '../../components/InfoBox'
import { SearchField } from '../../components/inputs/SearchField'
import { Loading } from '../../components/Loading'
import { AccountCarrierForm } from './AccountCarrierForm'
import { useAccountCarriers, useSearchAvailableCarriers } from './queries'

export const AddCarrierContent = ({ onSuccess }: { onSuccess: () => void }) => {
  const [carrierId, setCarrier] = useState<string>()

  return carrierId ? (
    <AccountCarrierForm
      carrierId={carrierId}
      onCancel={() => setCarrier(undefined)}
      onSuccess={onSuccess}
    />
  ) : (
    <AvailableCarrierList onSelect={c => setCarrier(c)} />
  )
}

type ListProps = {
  onSelect: (carrierId: string) => void
}
const AvailableCarrierList = ({ onSelect }: ListProps) => {
  const provisionedQuery = useAccountCarriers()
  const { search, setSearch, searchResults, isLoading } =
    useSearchAvailableCarriers()

  if (!provisionedQuery.data || isLoading) {
    return <Loading />
  }

  const visibleCarriers = searchResults.filter(
    i => !provisionedQuery.data.some(p => p.carrierId === i.carrierId),
  )

  return (
    <>
      <div className="bg-fv-blue-100 -m-8 mb-4 border-b px-8 py-4">
        <SearchField
          className="mb-0"
          initialValue={search}
          autoFocus
          onChange={value => setSearch(value)}
          placeholder="Search for your carriers & brokers"
        />
      </div>
      <div className="-mx-8 flex-1 overflow-auto px-8">
        {orderBy(visibleCarriers, c => c.name).map(c => (
          <div className="border-b" key={c.carrierId}>
            <FvButton
              theme="plain"
              icon="plus"
              onClick={() => onSelect(c.carrierId)}
            >
              {c.name}
            </FvButton>
          </div>
        ))}

        <InfoBox className="mb-0 mt-6 leading-5">
          Don't see your carrier or broker on our list?{' '}
          <FvLinkButton
            theme="underlined"
            href="mailto:support@freightview.com"
          >
            Tell us who else you use
          </FvLinkButton>
        </InfoBox>
      </div>
    </>
  )
}
