import { useQuery } from '@tanstack/react-query'
import orderBy from 'lodash/orderBy'
import { useMemo, useState } from 'react'

import {
  type CarrierMode,
  type CarrierProvisioningDetails,
  type DetailedAccountProvisioningDto,
} from '@fv/client-types'

import { useFuseSearch } from '../../hooks/useFuseSearch'
import { shipperFetch } from '../../utils/shipperFetch'

interface CarriersFilter {
  mode?: CarrierMode
  activeOnly?: boolean
}
export const provisioningQueryKeys = {
  all: ['provisioning'],
  account: ['provisioning', 'account'],
  accountCarrier: (carrierId: string) => [
    ...provisioningQueryKeys.account,
    carrierId,
  ],
  carriers: (filter?: CarriersFilter) => [
    'provisioning',
    'carriers',
    !!filter?.activeOnly,
    filter?.mode ?? 'all',
  ],
  carrier: (carrierId: string) => [
    ...provisioningQueryKeys.carriers(),
    carrierId,
  ],
}

export const useAvailableCarriers = (filter?: CarriersFilter) => {
  return useQuery(
    provisioningQueryKeys.carriers(filter),
    () =>
      shipperFetch<CarrierProvisioningDetails[]>(`/provisioning/carriers`, {
        query: filter,
      }),
    {
      select: data => orderBy(data, c => c.name.trim()),
    },
  )
}
export const useSearchAvailableCarriers = (filter?: CarriersFilter) => {
  const [search, setSearch] = useState('')
  const availableQuery = useAvailableCarriers(filter)
  const normalizedCarriers = useMemo(
    () =>
      availableQuery.data?.map(d => ({
        ...d,
        normalizedName: d.name.replace(/\./g, ''),
      })) ?? [],
    [availableQuery],
  )
  const searchItems = useFuseSearch(normalizedCarriers, {
    keys: ['normalizedName', 'code'],
    threshold: 0.2,
  })

  const searchResults = (
    search
      ? searchItems.search(search).map(i => i.item)
      : availableQuery.data ?? []
  ).filter(
    i => i.code !== 'fxfd', // TODO fxfe - we're no longer showing fxfd
  )
  return {
    search,
    setSearch,
    searchResults,
    carrierResults: availableQuery.data ?? [],
    isLoading: availableQuery.isLoading,
  }
}
export const useCarrierProvisioningDetails = (carrierId: string) => {
  return useQuery(provisioningQueryKeys.carrier(carrierId), () =>
    shipperFetch<CarrierProvisioningDetails>(
      `/provisioning/carriers/${carrierId}`,
    ),
  )
}

export const useAccountCarriers = (enabled?: boolean) => {
  return useQuery(
    provisioningQueryKeys.account,
    () =>
      shipperFetch<DetailedAccountProvisioningDto[]>(
        `/provisioning/account/carriers`,
      ),
    {
      enabled,
      select: data => orderBy(data, c => c.carrierName.trim()),
    },
  )
}

export const useAccountCarrier = (carrierId: string) => {
  const query = useAccountCarriers()
  const data = query.data?.find(d => d.carrierId === carrierId)
  return {
    ...query,
    data,
  }
}
