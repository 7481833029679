import clsx from 'clsx'
import { Fragment } from 'react'

import { FvButton, Icon, Tooltip } from '@fv/client-components'
import { militaryToStandardTime, parseQueryString } from '@fv/client-core'

import CustomFilterForm from '../../components/CustomFilterForm/CustomFilterForm'
import { SearchField } from '../../components/inputs/SearchField'
import {
  getFilterQueryData,
  isSavedFilterGroup,
  type SavedFilter,
  type SavedFilterGroup,
} from '../../types/SavedFilter'
import { ExpanderButton } from './ExpanderButton'
import { FilterDescription } from './FilterDescription'
import { FilterItem } from './FilterItem'
import { useFilterControls } from './hooks'
import {
  useShipmentFilterActions,
  useShipmentFilterListContext,
} from './ShipmentFilterListProvider'
import { type FilterControlProps } from './types'

export const ShipmentFilterPanel = ({
  onScheduleOpen,
  onChange,
}: FilterControlProps) => {
  const {
    search,
    isCustomFilter,
    filters,
    canSearch,
    searchParams,
    activeFilter,
    defaultFilter,
    setSearch,
  } = useFilterControls()
  const { listType, editingFilter, isCreating, forcePanelOpen } =
    useShipmentFilterListContext(s => s)
  const { setEditingFilter, setIsCreating, setForcePanelOpen } =
    useShipmentFilterActions()
  const showForm = isCreating || !!editingFilter

  const theFilter = activeFilter ?? defaultFilter
  const activeFilterTitle = isCustomFilter
    ? 'Custom filter'
    : theFilter?.fullLabel ?? theFilter.name

  const activeId = isCustomFilter ? undefined : theFilter?._id

  const handleFilterSelect = (filter?: SavedFilter | SavedFilterGroup) => {
    onChange?.({
      ...(filter && getFilterQueryData(filter)),
    })
  }

  const isSchedulableList = listType === 'readyCompletedList'
  const scheduledReport = theFilter?.scheduledReports?.[0]
  const scheduledTime =
    isSchedulableList && scheduledReport && scheduledReport.enabled
      ? militaryToStandardTime(scheduledReport.time)
      : undefined
  const canSchedule = isSchedulableList && !theFilter?.isLocked

  return (
    <>
      <div
        className={clsx('shipment-view-filter-ctn overflow-hidden', {
          'translate-x-0': forcePanelOpen,
        })}
      >
        {!showForm && (
          <>
            <div className="filter-summary">
              <div>
                <span className="limit-characters" title={activeFilterTitle}>
                  <Icon icon="list" className="color-dark" />
                  <span>{activeFilterTitle}</span>
                </span>
                {!isCustomFilter && canSchedule && (
                  <>
                    <div className="border-t-fv-blue-300 mx-2 h-px flex-1 border-t border-dotted" />
                    <div className="relative">
                      <Tooltip
                        label={
                          scheduledTime
                            ? `'${activeFilterTitle}' report will be sent ${scheduledReport?.frequency} via email at ${scheduledTime}`
                            : 'Schedule a daily report'
                        }
                      >
                        <FvButton
                          icon={scheduledTime ? 'paper-plane' : 'clock'}
                          transform="shrink-2"
                          className="testbutton flex items-center"
                          onClick={onScheduleOpen}
                        >
                          {scheduledTime && (
                            <span className="-ml-1 text-xs">
                              {scheduledTime}
                            </span>
                          )}
                        </FvButton>
                      </Tooltip>
                    </div>
                  </>
                )}

                {isCustomFilter && (
                  <>
                    <div className="border-fv-blue-300 mx-2 h-px flex-1 border-t border-dotted" />
                    <div className="flex divide-x last:pr-0 [&>div]:px-2">
                      {!activeFilter && (
                        <div>
                          <FvButton
                            title="Edit filter"
                            icon="pen"
                            onClick={() => {
                              setEditingFilter({
                                queryString: searchParams.toString(),
                              })
                            }}
                          />
                        </div>
                      )}
                      <div className="last:pr-0">
                        <FvButton
                          title="Clear filter"
                          icon="times"
                          iconClass="text-fv-orange"
                          onClick={() => handleFilterSelect(defaultFilter)}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <FilterDescription isCustomFilter={isCustomFilter} />

              {canSearch && (
                <SearchField
                  initialValue={search}
                  onChange={search => setSearch(search)}
                />
              )}
            </div>

            <div className="relative flex-1 basis-full overflow-auto">
              <ul className="standard-list standard-list--filtered-views standard-list--fcb-1 pb-6">
                {filters.map(f => (
                  <Fragment key={f._id}>
                    <FilterItem
                      onEdit={() => setEditingFilter(f)}
                      filter={f}
                      isActive={f._id === activeId}
                      key={f._id}
                      onSelect={() => handleFilterSelect(f)}
                      onRemoved={() => handleFilterSelect(undefined)}
                      className={clsx({
                        'bg-white': isSavedFilterGroup(f) && f._id !== activeId,
                      })}
                    />
                    {isSavedFilterGroup(f) &&
                      f.children.map(c => (
                        <FilterItem
                          onEdit={() => setEditingFilter(c)}
                          onRemoved={() => handleFilterSelect(undefined)}
                          filter={c}
                          isNested
                          isActive={c._id === activeId}
                          key={c._id}
                          onSelect={() => handleFilterSelect(c)}
                          className="b1450:border-none"
                        />
                      ))}
                  </Fragment>
                ))}
              </ul>
            </div>
          </>
        )}

        <ExpanderButton
          onClick={() => {
            setIsCreating(!isCreating)
          }}
        >
          {!editingFilter && <span>Create a custom filter</span>}
          {editingFilter && (
            <span className="limit-characters">
              Editing / {editingFilter.name ?? 'Custom'} filter
            </span>
          )}
        </ExpanderButton>

        {showForm && (
          <CustomFilterForm
            onClose={() => {
              setIsCreating(false)
              setEditingFilter(undefined)
            }}
            editing={editingFilter}
            onFilter={(qs: string) => {
              setForcePanelOpen(false)
              setIsCreating(false)
              setEditingFilter(undefined)
              onChange?.(parseQueryString(qs))
            }}
          />
        )}
      </div>
      {forcePanelOpen && (
        <div
          className="fixed left-0 top-0 z-[99] h-screen w-screen bg-black bg-opacity-60"
          onClick={() => {
            setForcePanelOpen(false)
            setIsCreating(false)
            setEditingFilter(undefined)
          }}
        />
      )}
    </>
  )
}
