import upperFirst from 'lodash/upperFirst'

import { Icon, Tooltip } from '@fv/client-components'
import { type QuoteCharge } from '@fv/client-types'

import { useCurrency } from '../../hooks/settings'

type rateChargesProps = {
  charges?: QuoteCharge[]
  amount: number
}

export const RateCharges = ({ charges, amount }: rateChargesProps) => {
  const sortedCharges = sortCharges(charges)
  const priceParts = useCurrency()
  const [dollars, cents] = priceParts(amount)

  const generateToolTip = (name: string, desc: string | undefined) => {
    const ignoreList = ['linehaul', 'discount', 'base rate', 'fuel']
    if (!ignoreList.includes(name) && desc) {
      return (
        <Tooltip label={desc}>
          <Icon icon="question-circle" className="color-warning ml-1" />
        </Tooltip>
      )
    }
    return null
  }

  return (
    <ul className="standard-list standard-list--lcp-0 standard-list--fcp-0 standard-list--lcb-0 p-6">
      {sortedCharges?.map(c => {
        const [dollars, cents] = priceParts(c.amount)
        const name =
          c.code === 'FVMU' && c.providerDetail ? c.providerDetail : c.name

        return (
          <li
            className="standard-list__item flex border-[#d8cfaf]"
            key={`c.name-${c.amount}`}
          >
            <div className="pr-4">
              {name ? upperFirst(name) : c.code === 'OTR' ? 'Other' : c.code}
              {generateToolTip(name, c.providerDetail)}
            </div>
            <div className="ml-auto font-mono">
              {c.name === 'discount' && '-'}
              {dollars}.{cents}
            </div>
          </li>
        )
      })}

      <li className="standard-list__item flex border-[#d8cfaf]">
        <div className="pr-4">
          Total cost of shipment{' '}
          <Icon icon="arrow-right" className="color-dark" transform="down-.5" />
        </div>
        <div className="ml-auto font-mono">
          ${dollars}.{cents}
        </div>
      </li>
    </ul>
  )
}

function sortCharges(charges?: QuoteCharge[]): QuoteCharge[] {
  if (!charges) return []

  return charges.slice().sort((a, b) => {
    if (a.name === 'linehaul') return -1
    if (b.name === 'linehaul') return 1
    if (a.name === 'base rate' || a.name === 'baseRate') return -1
    if (b.name === 'base rate' || b.name === 'baseRate') return 1
    if (a.name === 'discount') return -1
    if (b.name === 'discount') return 1
    if (a.name === 'fuel') return -1
    if (b.name === 'fuel') return 1
    return 0
  })
}
